import React from 'react';
import styled from "styled-components";
import LandingHeaderSvg from "../svg-components/LandingHeaderSvg";
import EcoBrandSvg from "../svg-components/EcoBrandSvg";
import chelBrand from "../images/ChelBrand.png";
import HistorySvg from "../svg-components/HistorySvg";
import PractiseSvg from "../svg-components/PractiseSvg";
import star_image from "../images/star.png";
import logo2 from "../images/logoOPCH.png";
import GreenAreaSvg from "../svg-components/GreenAreaSvg";
import LogoSvg from "../svg-components/LogoSvg";

const Body = styled.div`

`;

const Cred = styled.a`
  padding: 1% 4%;
  background: #97F1E0;
  border-radius: 37px;
  font-weight: 500;
  color: #018168;
  font-size: min(1.2vw, 12px);
  display: flex;
  align-self: center;

  &:hover {
    color: #018168;
    opacity: .8;
  }
`;

const Footer = styled.div`
  display: flex;
  background: #e4f7f3;
  padding: 3% 20%;
  justify-content: space-between;

  svg {
    max-height: 66px;
    width: 12vw;
    height: 4vw;
  }

  @media (max-width: 1500px) {
    padding: 3% 15%;
  }
  @media (max-width: 1000px) {
    padding: 3% 10%;
  }
  @media (max-width: 700px) {
    padding: 3% 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
`;

const StyledLandingPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  font-family: 'Montserrat', sans-serif;

  h1, h2 {
    font-family: 'Comfortaa', cursive;
  }

  h2 {
    font-size: min(3vw, 42px);
    margin-bottom: min(3vw, 42px);
    font-weight: 700;

    &.green {
      color: #2ebfa3;
      font-size: min(2.8vw, 28px);
    }
  }
`;


const Text = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 32%;
  bottom: 48%;
  left: 18%;
  position: absolute;
  line-height: min(2vw, 25px);
  pointer-events: none;

  h1 {
    color: #ffffff;
    font-size: min(4vw, 68px);
    line-height: min(4vw, 68px);
  }

  p {
    width: 80%;
    font-size: min(1.8vw, 18px);
  }
`;

const Brands = styled.div`
  padding: min(1vw, 20px) min(1.5vw, 35px);
  border-radius: 108px;
  display: flex;
  gap: 0 min(2vw, 34px);
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  left: 18%;
  top: 5%;

  a {
    max-height: 46px;
    display: flex;
  }

  img, svg {
    height: min(3vw, 46px);
    width: min(3vw, 46px);
    object-fit: contain;
    max-height: 46px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Right = styled(Left)`
  margin: 0;
`;

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;


    li {
      display: flex;
      font-weight: 500;
      font-size: min(1.8vw, 18px);

      &:not(:last-child) {
        margin-bottom: min(3vw, 26px);
      }

      p {
        margin: 0;
      }

      span {
        color: #07967A;
      }

      img {
        position: relative;
        margin-right: min(1.8vw, 18px);
        filter: drop-shadow(0 0 15px rgb(46, 191, 163));
        width: min(1.8vw, 18px);
        height: min(2vw, 18px);
        object-fit: contain;
      }
    }
  }
`;

const HistoryTop = styled.div`
  display: flex;
`;

const HistoryButton = styled.a`
  background: #97F1E0;
  border-radius: 37px;
  border: none;
  border-bottom: min(.4vw, 4px) solid #8ce4d3;
  color: #465b57;
  font-size: min(1.6vw, 16px);
  font-weight: 600;
  padding: 1.3% 6%;
  text-transform: uppercase;
  margin-right: 13px;

  &:hover {
    color: #465b57;
    opacity: .8;
  }
`;

const HistoryBottom = styled.div`
  width: 100%;
  display: flex;
  color: #6d6d6d;
  font-weight: 500;
  font-size: min(1.2vw, 12px);
  align-items: center;
  margin-top: 48px;

  span {
    margin-right: 3px;
  }

  .additionalText {
    font-size: min(1.2vw, 12px);
    display: flex;
    width: 30%;
  }
`;

const History = styled(Block)`
  flex-direction: column;
  padding: 5% 20%;
  color: #6d6d6d;

  p {
    color: #6d6d6d;
    font-size: min(1.8vw, 18px);
  }

  ${Left} {
    width: 50%;
  }

  ${Right} {
    width: 40%;
  }

  @media (max-width: 1500px) {
    padding: 5% 10%;
  }
  @media (max-width: 1000px) {
    padding: 5% 5%;
  }
  @media (max-width: 700px) {
    padding: 5% 1%;
  }
`;

const Practice = styled(Block)`
  //padding: 50px 400px 50px 250px;
  padding: 3% 21% 3% 13%;
  background: linear-gradient(250.45deg, #F8FFFE -25.91%, #EEFFFC 107.08%);


  ${Left} {
    width: 50%;
  }

  ${Right} {
    width: 50%;
  }
`;

const Top = styled(Left)`
  align-self: flex-start;
`;

const Bottom = styled(Left)`
  margin: 0;
  flex-direction: row;
  align-items: center;
`;

const GreenArea = styled(Block)`
  flex-direction: column;
  padding: 10% 11% 0 20%;

  ${Top} {
    margin-bottom: 10px;
  }

  ${Bottom} {
    padding-bottom: 40px;

    ${Left} {
      width: 40%;
    }

    ${Right} {
      width: 60%;
      position: relative;
      bottom: -51px;
    }
  }

  h2 {
    margin: 0;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1500px) {
    padding: 10% 8% 0 15%;
  }
  @media (max-width: 1000px) {
    padding: 10% 6% 0 10%;
  }
  @media (max-width: 700px) {
    padding: 10% 2% 0 5%;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px 0;
  font-size: min(1.8vw, 18px);
`;

const ListItem = styled.div`
  background: #F7FFFE;
  border: 1px solid #CDF3EC;
  border-radius: 12px;
  padding: min(2.1vw, 21px);
  font-weight: 500;

  p {
    color: #3e3e3e;
  }

  span {
    font-weight: 700;
    color: #2ebfa3;
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  color: #ffffff;
  width: 100%;
  align-self: center;
  align-items: center;
`;

function LandingPage() {
    return (
        <StyledLandingPage>
            <Content>
                <Header>
                    <Brands>
                        <a href="https://vk.com/ecostandart74" target={"_blank"}
                           rel={"noopener noreferrer"}><EcoBrandSvg/></a>
                        <a href="https://mineco.gov74.ru" target={"_blank"} rel={"noopener noreferrer"}><img
                            src={chelBrand} alt=""/></a>
                        <a href="https://op74.ru" target={"_blank"} rel={"noopener noreferrer"}><img src={logo2}
                                                                                                     alt=""/></a>
                        <a href="https://priroda.gov74.ru" target={"_blank"} rel={"noopener noreferrer"}><img
                            src={chelBrand} alt=""/></a>
                    </Brands>
                    <LandingHeaderSvg/>

                    <Text>
                        <h1>Зеленый пояс<br/> Челябинска</h1>
                        <p>Подробнее о каждом участке можно узнать нажав на карту</p>
                    </Text>
                </Header>
                <Body>
                    <History>
                        <HistoryTop>
                            <Left>
                                <h2>Режим особой охраны</h2>
                                <p>На территориях, входящих в состав лесопарковых зеленых поясов, запрещается:</p>
                                <ul>
                                    <li><img src={star_image} alt={""}/> Использование токсичных химических препаратов
                                    </li>
                                    <li><img src={star_image} alt={""}/> Проводить сплошную рубку леса
                                    </li>
                                    <li><img src={star_image} alt={""}/> Размещение отходов</li>
                                    <li><img src={star_image} alt={""}/> Строительство объектов, не связанных с
                                        созданием
                                        лесной инфраструктуры
                                    </li>
                                    <li><img src={star_image} alt={""}/> Разработка месторождений, за исключением
                                        природных
                                        лечебных ресурсов
                                    </li>
                                </ul>
                            </Left>
                            <Right>
                                <HistorySvg/>
                            </Right>
                        </HistoryTop>
                        <HistoryBottom>
                            <HistoryButton href={"https://www.consultant.ru/document/cons_doc_LAW_34823/0d846a6bb002799dcb490248c1baf1c2d6962a17/"}
                                           target={"_blank"} rel={"noopener noreferrer"}>
                                Подробнее
                            </HistoryButton>

                            <div className={"additionalText"}>
                                <span>*</span>Подробнее о внесении изменений в Федеральный закон «Об
                                охране окружающей
                                среды»
                            </div>
                        </HistoryBottom>
                    </History>
                    <Practice>
                        <Left>
                            <PractiseSvg/>
                        </Left>
                        <Right>
                            <h2>Всероссийская практика</h2>
                            <ul>
                                <li><img src={star_image} alt={""}/> Закон о создании «Зеленого пояса» вступил в силу в
                                    Российской Федерации в 2017 году. Уже применяется в 40 городах по всей стране.
                                </li>
                                <li><img src={star_image} alt={""}/> <p>Зеленый пояс г. Екатеринбурга составляет <span>18 444 Га</span>
                                </p>
                                </li>
                                <li><img src={star_image} alt={""}/> <p>Площадь зеленого пояса Уфы составляет <span>1 700 Га</span>
                                </p></li>
                                <li><img src={star_image} alt={""}/> <p>Вологды – <span>65 848 Га</span></p></li>
                                <li><img src={star_image} alt={""}/> <p>Читы – <span>21 786 Га</span></p></li>
                            </ul>
                        </Right>
                    </Practice>
                    <GreenArea>
                        <Top>
                            <h2> «Зеленый пояс» Челябинска</h2>
                            <h2 className="green">Составит 31 749,6 Га или 317.496 Км2</h2>
                        </Top>
                        <Bottom>
                            <Left>
                                <List>
                                    <ListItem>
                                        <p>Леса Челябинска </p><span>Площадь 1708,78 Га</span>
                                    </ListItem>
                                    <ListItem>
                                        <p>Леса Копейского городского округа</p>
                                        <span>Площадь 1235,04 Га</span>
                                    </ListItem>
                                    <ListItem>
                                        <p>Красноармейский и Сосновский районы</p>
                                        <span>Площадь 4316,33 Га</span>
                                    </ListItem>
                                    <ListItem>
                                        <p>Земли ООПТ Челябинский (городской), Каштакский, Ужовский  боры</p>
                                        <span>Площадь 4173,95 Га</span>
                                    </ListItem>
                                    <ListItem>
                                        <p>Земли лесного фонда Красноармейское, Долгодеревенское, Кременкульское,
                                            Смолинское, Еткульское лесничества</p>
                                        <span>Площадь 20 315,5 Га</span>
                                    </ListItem>
                                </List>
                            </Left>
                            <Right>
                                <GreenAreaSvg/>
                            </Right>
                        </Bottom>
                    </GreenArea>
                </Body>
                <Footer>
                    <a href="https://t.me/ecostandart74" target={"_blank"} rel={"noopener noreferrer"}><LogoSvg/></a>
                    <Cred href="https://www.behance.net/nezovitin" target={"_blank"} rel={"noopener noreferrer"}>
                        Designed by<br/>
                        Nezovitin N.
                    </Cred>
                </Footer>
            </Content>
        </StyledLandingPage>
    );
}

export default LandingPage;