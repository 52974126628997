import React from 'react';

function RemoveSvg(props:any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="9" stroke="#FF7777"/>
            <path d="M18 18L6 6" stroke="#FF7777"/>
        </svg>
    );
}

export default RemoveSvg;