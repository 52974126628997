import React from 'react';
import {Form, Input, Select} from "antd";

const {Option} = Select;

function UserCreateForm({onFinish, form}:any) {
    return (
        <Form onFinish={onFinish} form={form} hideRequiredMark layout={"vertical"}>
            <Form.Item rules={[{
                required: true,
                message: "Пожалуйста, введите Имя"
            }]} label={"Имя"} name={"login"}>
                <Input/>
            </Form.Item>
            <Form.Item rules={[{
                required: true,
                message: "Пожалуйста, введите Пароль"
            }]} label={"Пароль"} name={"password"}>
                <Input.Password/>
            </Form.Item>
            <Form.Item initialValue={"guest"} label={"Роль"} name={"role"}>
                <Select>
                    <Option value={"admin"}>Администратор</Option>
                    <Option value={"moder"}>Модератор</Option>
                    <Option value={"guest"}>Гость</Option>
                </Select>
            </Form.Item>
        </Form>
    );
}

export default UserCreateForm;