import React from 'react';

function LocationSvg(props:any) {
    return (
        <svg {...props} width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.666748 11.757C0.666748 5.62386 5.79192 0.666672 11.9913 0.666672C18.2082 0.666672 23.3334 5.62386 23.3334 11.757C23.3334 14.8476 22.2094 17.7168 20.3594 20.1488C18.3185 22.8314 15.803 25.1686 12.9715 27.0032C12.3234 27.4272 11.7386 27.4592 11.0273 27.0032C8.17973 25.1686 5.6642 22.8314 3.64075 20.1488C1.78939 17.7168 0.666748 14.8476 0.666748 11.757ZM8.25905 12.1023C8.25905 14.1569 9.93563 15.7729 11.9913 15.7729C14.0484 15.7729 15.7411 14.1569 15.7411 12.1023C15.7411 10.0637 14.0484 8.36911 11.9913 8.36911C9.93563 8.36911 8.25905 10.0637 8.25905 12.1023Z"
                  fill="#2C7AF6"/>
        </svg>
    );
}

export default LocationSvg;