import React from 'react';
import styled from "styled-components";
import ExitSvg from "../svg-components/ExitSvg";
import axios from "axios";
import {api} from "../config";
import {Dropdown, Menu, message} from "antd";
import History from "../utils/history";
import ArrowSvg from "../svg-components/ArrowSvg";
import LogoSvg from "../svg-components/LogoSvg";
import {NavLink} from "react-router-dom";

const Logo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 0;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const Item = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;

  &:not(:last-child) {
    margin-right: 50px;
  }

  svg {
    margin-left: 10px;
    margin-top: 4px;
    width: 10px;
    height: 10px;

    path {
      fill: #6d6d6d;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const StyledHeader = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  //background: #f3f3f3;
  background: #F4FFFD;
  justify-content: space-between;
  padding: 0 14px;
  overflow: hidden;
  flex: 1 0 auto;

  h1 {
    margin: 0;
  }
`;


function Header({onUserCreate, user, setUser}: any) {
    const exit = () => {
        axios(`${api}/exit`).then(() => {
            setUser(undefined);
            History.push("/login");
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const userMenu = <Menu>
        <StyledMenuItem onClick={exit}><ExitSvg style={{height: 20, width: 20}}/>Выйти</StyledMenuItem>
    </Menu>

    const usersMenu = <Menu>
        <StyledMenuItem onClick={onUserCreate}>Создать пользователя</StyledMenuItem>
    </Menu>

    return (
        <StyledHeader>
            <NavLink to={"/"}>
                <Logo>
                    <LogoSvg/>
                </Logo>
            </NavLink>
            <RightSide>
                {user?.type === "admin" && <Dropdown trigger={["click"]} overlay={usersMenu}>
                    <Item>Пользователи <ArrowSvg/></Item>
                </Dropdown>}
                {user.type !== "guest" && <Dropdown trigger={["click"]} overlay={userMenu}>
                    <Item>{user?.login} <ArrowSvg/></Item>
                </Dropdown>}
            </RightSide>
        </StyledHeader>
    );
}

export default Header;