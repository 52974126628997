import React from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const StyledLandingHeaderSvg = styled.svg`
  .chel_area {
    cursor: pointer;
    transition: all .2s;

    &:hover {
      opacity: .5;
    }
  }

  path:not(.chel_area) {
    pointer-events: none;
  }
`;

function LandingHeaderSvg(props: any) {
    return (
        <StyledLandingHeaderSvg {...props} version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio={"xMidYMin meet"}
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px" y="0px"
                                viewBox="0 0 1920 1080" enableBackground="new 0 0 1920 1080" xmlSpace="preserve">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="556.5366" y1="767.3226" x2="1704.0366"
                            y2="200.8226" gradientTransform="matrix(1 0 0 -1 0 1077.89)">
                <stop offset="0" style={{stopColor: "#2EBFA3"}}/>
                <stop offset="1" style={{stopColor: "#48EACA"}}/>
            </linearGradient>
            <path fill="url(#SVGID_1_)" d="M0,0h1920v1019.5c0,0-584.35,60.5-960,60.5c-375.647,0-960-60.5-960-60.5V0z"/>
            <defs>
                <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="-732.91" y="-4395.06"
                        width="5772.47" height="5708.51">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
                </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="-732.91" y="-4395.06" width="5772.47" height="5708.51"
                  id="mask0_104_195_1_">
                <path fill="#2EBFA3" filter="url(#Adobe_OpacityMaskFilter)" d="M0,0h1920v1022.71c0,0-584.35,57.29-960,57.29
		c-375.647,0-960-57.29-960-57.29V0z"/>
            </mask>
            <g mask="url(#mask0_104_195_1_)">
                <g opacity="0.15">
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-679.07-197.759l79.37-6.612l23.476-28.834l1.418-0.901
			l3.737-0.382l32.977,4.933l0.9,1.418l0.901,1.419l26.247,35.075l55.532-3.417l1.418-0.9l1.801,2.836l50.538,95.272l121.955-77.427
			l9.295-19.832l1.418-0.9l42.025-24.691l1.418-0.9l2.319,0.518l31.154,24l41.62-6.525l0.901,1.418l46.37,10.36l15.664-75.614
			l1.418-0.9l1.418-0.9l110.092-67.907l-41.286-71.296l-0.9-1.418l5.963-37.616l-39.415-8.806l-48.236,52.515l-1.418,0.9l-0.9-1.418
			l-120.337,4.761l-1.418,0.9l-0.9-1.418l-115.118-62.231l-110.027,2.195l-126.457,70.336l-22.214,55.894l-1.418,0.9l-46.414,33.447
			l-0.674,30.278l0.901,1.418L-679.07-197.759z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M175.747,988.816l24.695,42.034l55.488,40.39l23.342-22.78
			l7.629-28.73l49.994-5.87l15.691,27.85l3.963,31.31l21.767,6.08l44.974-10.64l48.916,42.57l55.601,56.24l8.196,50.52l38.628,23.23
			l25.433-53.95l22.037-6.04l30.772,20.27l40.541,41.92l39.55,2.75l20.527-42.88l32.098-18.39l75.768-12.29l15.172,30.17
			l27.688,12.27l33.381-13.23l6.481-39.94l43.152,8.43l46.119,0.56l25.33,55.56l26,25.28l57.44,15.27l33.25-7.18l17.56-35.02
			l27.19-7.31l37.21,24.13l82.99-22.84l14.86-39.28l2.76-61.46l-125.4-81.57l-6.06-0.13l-2.32-0.52l-0.9-1.42l-32.95-26.83
			l-0.9-1.42l-0.39-3.74l14.86-39.28l-6.42-25.776l-33.09-20.781l-88.15,24.124l-28.63,30.118l10,53.355l-0.52,2.32l-2.83,1.8
			l-46.67,23.65l-1.41,0.9l-2.32-0.52l-64.921-14.5l-85.963,30.7l-1.418,0.9l-0.901-1.42l-36.827-20.4l-1.801-2.84l0.518-2.31
			l8.551-49.213l-31.041-8.152l-1.801-2.836l-11.706-18.438l-0.382-3.737l1.417-0.9l21.541-25.616l-25.978-47.186l-37.387,25.727
			l-10.331,24.469l-2.454,5.538l-5.02-4.774l-39.889-50.293l-3.219-1.936l2.836-1.801l12.515-17.895l-17.875-34.421l-30.14-6.734
			l-0.901-1.418l-0.9-1.419l-46.306-76.069l-38.763-17.18l-2.318-0.518l-64.833-102.117l-47.767-31.362l-0.901-1.418l-0.9-1.418
			l-16.704-45.114l-45.853-12.678l2.701,4.254l14.634,54.389l0.901,1.418l1.261,27.059l0.901,1.418l-1.418,0.901l-50.08,93.484
			l3.558,49.479l-1.418,0.901l0.901,1.418l-44.499,52.132l-25.661,22.261l-24.689,83.334l-1.418,0.901l-74.91,57.509
			L175.747,988.816z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-233.515,1202.88l56.162,10.11l20.058,41l8.083,34.67
			l45.583,24.79l29.418-44.55l-6.26-53.74l7.899-40.83l63.026-26.08l16.407-46.24l20.371-14.92l25.121,1.96l54.021-40.27
			l35.339-38.36l26.177-24.576l-0.382-3.737l-13.89-25.012l-17.761-18.573l-53.057-24.024l-2.318-0.518l-0.9-1.418l-26.362-50.923
			l-58.729-20.423l-0.901-1.419l-40.159-38.182l-19.945-25.147l-30.928,7.696l-1.418,0.9l-105.008,6.969l-2.318-0.518l-0.901-1.418
			l-29.332-43.067l-84.68,35.853l-1.418,0.9l-112.863,3.997l-2.319-0.518l-0.9-1.418l-17.109-26.948l-23.951-12.653l-42.407,20.954
			l-1.418,0.9l-2.319-0.518l-114.352-54.758l-52.781,89.23l-1.418,0.9l-38.558,36.42l30.864,58.014l0.9,1.418l0.901,1.418
			l-5.559,19.449l30.75,42.167l43.017,14.478l39.436-13.097l1.418-0.901l101.115,21.373l2.318,0.518l0.901,1.418l28.814,45.384
			l0.901,1.42l-8.552,49.21l17.492,30.68l23.589-12.98l19.222-26.14l2.454-5.54l3.602,5.68l34.352,47.84l30.006,12.79l39.954-15.42
			l3.736-0.38l1.801,2.83L-233.515,1202.88z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-59.421,771.967l19.044,23.728l40.159,38.183l56.41,19.905
			l2.318,0.518l0.9,1.418l27.262,52.341l53.574,21.706l0.9,1.418l17.244,20.892l0.9,1.418l14.407,22.693l72.074-55.709l25.59-81.916
			l-0.9-1.418l1.418-0.9l25.66-22.261l41.663-50.332l-2.658-48.061l-0.901-1.419l1.418-0.9l50.08-93.484l-1.779-24.74
			l-14.634-54.389l-9.253-23.975L287.17,484.26l-26.021-3.379l-11.231,23.051l0.9,1.418l-1.418,0.9l-13.798,12.74l-1.418,0.901
			l-2.318-0.518l-61.431-24.678l-5.424,13.394l-12.692,67.757l-15.103,29.488l-1.418,0.901l-1.418,0.9l-34.282,11.815l-2.318-0.518
			L27.188,607.53l-44.187-3.786l-26.561,20.843l-44.613,36.284l-1.326,38.652l7.204,11.346l19.583-0.493l2.836-1.801l0.383,3.737
			l15.556,33.903l0.901,1.418l-1.936,3.219L-59.421,771.967z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-230.227,741.11l27.014,42.549l102.689-7.487l34.664-8.078
			l13.033-20.214l-12.855-29.649l-18.683,1.912l-2.319-0.518l-0.9-1.418l-9.905-15.601l0.518-2.319l1.844-40.97l0.518-2.318
			l1.418-0.901l43.712-37.702l27.979-21.743l1.418-0.9l2.319,0.517l45.605,2.886l70.591,10.902l32.863-10.915l13.167-26.269
			l12.693-67.758l5.424-13.394l-53.191-17.968l-3.219-1.937l1.936-3.218l2.857-23.705l-22.646-29.401l-62.26,33.558l-2.836,1.801
			l-0.9-1.418l-64.649-26.615l-43.442,25.592l-2.836,1.801l-3.219-1.937l-39.911-28.39l-82.814-27.021l-0.27,12.111l17.605,46.532
			l0.9,1.418l-1.439,22.804l0.383,3.737l-3.737,0.382l-86.211,20.905l46.823,73.751l0.901,1.418l-0.518,2.319l-4.793,26.923
			l31.289,17.944l0.9,1.418l0.901,1.419l6.033,22.039l-1.418,0.9l0.901,1.418L-230.227,741.11z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-225.699,328.301l11.189,20.756l0.9,1.419l-0.291,34.014
			l85.133,27.539l1.418-0.9l0.901,1.418l15.577,12l30.567-33.336l43.195-35.384l-17.74-40.477l-0.9-1.418l0.518-2.319l16.003-28.069
			l0.518-2.319l41.011-41.957l-20.441-44.732l-59.203-61.911l-111.084,28.737l-43.464,47.494l29.445,58.915l0.901,1.418
			l-0.518,2.319L-225.699,328.301z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-648.301,220.501l36.174,28.773l26.135,19.227l97.378,21.754
			l2.319,0.518l0.9,1.418l53.014,67.832l56.14,32.016l23.973-9.25l-4.75-16.884l0.517-2.319l2.836-1.8l47.832-34.348l-0.114-15.848
			l-0.382-3.737l3.736-0.382l23.32-0.876l2.837-1.801l0.9,1.419l17.243,20.892l24.739-1.777l22.214-55.893l-29.445-58.914
			l-1.801-2.837l2.836-1.8l43.982-49.814l-49.908-81.742l-1.801-2.836l4.254-2.701l47.314-32.029l12.019-37.48l1.935-3.219
			l2.319,0.518l73.179-0.691l4.206-84.259l1.035-4.638l4.637,1.036l70.726,4.846l24.129-37.209l3.893-28.341l-53.191-17.969
			l-98.278-23.172l-42.521,5.105l-1.418,0.901l-2.319-0.518l-29.736-24.9l-36.87,23.408l-9.296,19.832l-1.418,0.9l-127.109,78.71
			l-18.974,35.926l-0.517,2.319l-3.737,0.382l-25.504-5.697l-36.487,27.145l-2.609,33.496l0.9,1.418l-1.418,0.9l-23.342,22.779
			l-43.89,87.565l-1.418,0.9l-60.594,42.451L-648.301,220.501z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M-103.373,3.881l-73.179,0.691l-12.919,36.062l-1.418,0.9
			l-44.996,32.547l45.789,78.388l112.502-29.636l2.319,0.518l2.318,0.517l60.487,67.067l20.058,40.994l19.222-26.133l70.79-60.864
			l1.418-0.9l66.11-18.093l2.319,0.518l2.319,0.518l35.025,17.562l11.253-44.954l-41.555-59.186l-31.672-21.681L78.933-0.424
			L-29.719,44.678l-2.319-0.518l-33.495-2.614l-2.319-0.518l-0.9-1.418L-103.373,3.881z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M222.346,109.511l-11.635,41.217l-1.035,4.637l-4.637-1.036
			l-38.245-19.498l-64.692,17.193l-69.373,59.963l-20.64,27.034l0.901,1.419l-2.836,1.8l-42.947,45.176l-14.585,27.17l19.157,39.576
			l1.801,2.837l-2.836,1.8l-45.13,38.603l-29.149,32.436l14.677,10.582l43.443-25.592l1.418-0.9l2.319,0.518l65.55,28.032
			l62.778-35.877l3.737-0.382l1.801,2.837l26.766,32.756l0.9,1.418l-0.517,2.319l-2.34,21.385l48.554,16.933l4.637,1.036
			l62.331,26.096l10.961-10.939l11.749-25.369l0.518-2.319l3.736-0.382l29.241,5.315l1.418-0.9l76.624,32.941l2.319,0.518
			l-0.518,2.318l4.886,10.828l53.708,15.651l2.319,0.518l-0.518,2.318l17.605,46.533l44.548,29.426l8.509-5.402l-4.346-35.05
			l-6.53-41.624l-0.383-3.737l2.836-1.801l26.426-14.787l1.418-0.9l47.541-0.334l1.304-16.748l-30.502-32.374l-39.415-8.805
			l-0.9-1.419l-0.901-1.418l-18.639-41.895l-1.801-2.837l2.836-1.8l68.315-33.423l41.146-48.013l-19.923-47.05l-20.236,8.868
			l-1.418,0.9l-2.319-0.518l-20.349-6.98l-4.637-1.036l1.936-3.219l14.337-36.962l-3.985-9.41l-24.49,11.568l-3.736,0.383
			l-0.901-1.419l-14.159-12.9l-0.901-1.418l0.518-2.319l-2.545-32.214l-12.11-0.271l-30.071,52.921l-0.517,2.319l-2.319-0.518
			l-60.19,24.284l-1.418,0.9l-0.901-1.418l-46.1-22.47l-2.319-0.518l-42.095-34.964l-0.9-1.418L222.346,109.511z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M314.867,223.899l19.697,15.354l43.782,21.952l57.354-22.483
			l31.489-53.821l0.517-2.319l3.737-0.382l19.066,1.825l3.737-0.382l0.383,3.736l0.609,35.433l10.04,9.546l25.908-12.469
			l4.254-2.701l1.283,5.155l7.587,15.083l0.9,1.419l-1.418,0.9l-13.819,34.643l15.712,5.945l21.654-9.768l32.615-20.707
			l13.706-50.492l-45.405-74.651l-2.701-4.255l2.836-1.801l31.58-16.07l9.587-53.845l-13.981-62.762l1.418-0.9l9.069-51.527
			l-22.695-110.96l-25.121-1.961l-36.579-10.606l-23.341,22.779l-1.418,0.901l-2.319-0.518l-57.063-11.531l-57.623,34.594
			l12.606,19.856l14.273,28.748l-0.518,2.319l-19.513,60.148l-0.518,2.319l-2.318-0.518l-41.62,6.524l-25.909,12.469l33.005,108.394
			l0.9,1.418l-1.936,3.219l-49.384,41.304L314.867,223.899z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M620.617,200.92l-12.806,51.91l-0.518,2.318l-1.418,0.901
			l-31.197,19.807l20.823,48.468l0.901,1.419l-0.518,2.318l-43.081,51.232l-1.418,0.9l-65.48,31.622l16.456,35.321l37.997,9.706
			l1.418-0.9l0.901,1.419l32.303,35.21l0.9,1.418l-0.517,2.319l-0.022,21.903l-1.035,4.637l-3.737,0.383l-49.859-0.185
			l-23.589,12.987l5.629,40.205l6.147,37.887l-0.518,2.319l-1.418,0.9l-9.926,6.302l62.131,97.862l39.28,14.861l0.901,1.418
			l0.9,1.419l46.306,76.069l29.623,9.052l2.319,0.518l0.9,1.419l19.675,37.257l0.901,1.419l-0.518,2.319l-13.415,16.476
			l33.969,44.103l9.296-19.831l-0.901-1.419l1.419-0.9l43.059-29.328l2.837-1.801l1.801,2.837l30.48,54.277l1.801,2.836
			l-2.836,1.801l-21.541,25.616l9.905,15.601l31.042,8.152l4.636,1.036l-1.035,4.637L820,1041.46l32.708,17.05l84.545-29.8
			l0.901,1.42l1.418-0.9l64.918,14.5l43.83-21.86l-9.1-51.929l0.52-2.319l1.42-0.9l27.73-31.536l-14.14-34.803l-15.56-33.904
			l-1.8-2.837l2.84-1.8l36.75-39.256l-18.25-38.158l0.51-2.319l-4.16-84.912l1.41-0.901l-0.9-1.418l40.79-73.653l0.52-2.319
			l2.31,0.518l41.11-4.205l14.18-9.003l-13.87-46.915l-0.9-1.418l2.84-1.801l23.47-28.835l8.01-24.986l-37.99-9.706l-10.58,14.676
			l-14.59,27.17l-1.41,0.9l-1.42,0.9L989.5,565.703l-1.418,0.9l-34.395-4.033l-2.319-0.518l-62.444-41.944l-0.9-1.418l-0.9-1.418
			l-10.267-41.242l-0.9-1.418l0.517-2.319l29.688-56.657l0.518-2.319l28.744-14.269l1.418-0.901l0.901,1.418l38.38,13.443l0.9,1.418
			l30.237,44.485l27.43,2.479l17.81-25.234l0.52-2.318l1.41-0.9l24.49-11.569l1.42-0.9l21.39,2.343l1.42-0.9l55.64,12.431
			l11.61-19.314l-3.96-31.313l-39.28-14.861l-1.42,0.9l-0.9-1.418l-17.24-20.892l-0.9-1.418l-4.33-56.954l-17.35-36.74l-17.92,9.386
			l-2.32-0.518l-80.921,13.567l-0.9-1.419l-41.868-3.268l-0.901-1.418l-29.736-24.9l-22.441,24.198l-0.518,2.318l-3.219-1.936
			l-22.668-7.499l-0.9-1.418l-22.263-25.665l-15.06-14.318l-32.863,10.915l-3.737,0.382l-0.901-1.418l-27.148-36.493l-69.869,40.379
			l-4.254,2.7l-2.701-4.255l-31.516-49.64L620.617,200.92z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M458.604-518.08l-31.871,50.085l-1.418,0.9l-1.418,0.901
			l-160.561,32.288l-13.345,76.132l0.383,3.737l-3.736,0.382l-32.077-3.514l-2.318-0.518l-0.901-1.419l-49.525-78.006
			l-25.234-17.808l-3.219-1.936l0.518-2.319l31.1-182.923l-37.188-46.039l-53.192-17.969L37.08-674.323L4.152-597.698l-1.418,0.9
			l-1.418,0.9l-54.27,30.475l5.516,24.358l58.48,10.631l4.637,1.036l0.383,3.737l-17.081,76.514L-8.4-410.631l44.505,73.232
			l1.801,2.837l-4.254,2.701l-111.51,68.807l-17.464,72.776l46.37,10.36l0.901,1.418l56.41,19.905l3.219,1.937l-0.518,2.318
			l-3.51,32.078l-1.418,0.901l-26.064,40.427l-1.418,0.9l-2.318-0.518l-69.825-3.428l-5.106,82.841l35.139,33.41l32.077,3.515
			L75.849-8.416l2.318,0.518l1.418-0.9l66.202,19.658l1.418-0.9l32.573,23.099l0.9,1.418L222.618,97.4l0.9,1.418l69.852,106.889
			l12.359,10.064l-15.13-73.973l0.518-2.319l1.418-0.9l48.484-42.722L308.015-12.537l-1.801-2.837l2.836-1.801l30.162-15.17
			l1.418-0.9l40.202-5.624l17.06-54.61l-13.372-27.33l-12.989-23.593l-1.801-2.837l2.836-1.8l62.778-35.877l1.418-0.901
			l57.063,11.531l24.76-23.679l1.418-0.9l2.318,0.518l36.579,10.606l25.121,1.961l8.552-49.209l3.779-44.189l-39.932-6.487
			l-2.319-0.518l-0.9-1.418l-22.512-35.457l-1.801-2.837l1.419-0.9l70.315-102.352l-35.252-49.258L458.604-518.08z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M2056.22,334.206l51.06-32.412l88.46,45.323l38.15-18.253
			l-8.46-38.405l1.42-0.9l-0.9-1.418l24.95-95.446l-18.5-47.95l0.52-2.318l10.75-64.539l0.52-2.318l1.42-0.9l51.68-18.882
			l1.06-26.54l-14.52-38.54l-34.38-25.936l-47.29,10.126l-2.32-0.518l-2.32-0.518l-19.17-17.673l-0.91-1.418l0.52-2.319l4.03-34.397
			l-30.35-60.333l-0.9-1.418l26.13-106.138l-0.38-3.737l4.64,1.036l60.28,13.467l2.32,0.518l0.38,3.737l-1.84,40.97l28.22-11.95
			l2.32,0.518l96.37,4.488l45.13-38.603l1.41-0.9l1.42-0.9l63.91-2.763l47.69-28.292l35.39-82.163l-165.16-12.554l-0.9-1.418
			l-1.42,0.9l-121.42-72.159l-96.43-64.143l-35.7,12.715l-82.34,14.467l-1.42,0.901l-52.43-10.495l6.29,31.831l1.8,2.837l-2.84,1.8
			l-54.27,30.476l-3.76,22.285l0.9,1.418l-23.23,38.627l-1.41,0.901l-29.13,10.533l-7.5,22.667l-0.51,2.319l-1.42,0.9l-32.62,20.708
			l-1.41,0.9l-52.83,7.671l3.17,45.744l0.9,1.418l-1.42,0.9l-52.35,49.16l4.55,88.649l-0.52,2.319l-1.42,0.9l-106.73,63.787
			l9.45,77.574l39.24,58.668l0.9,1.418l-1.94,3.219l-22.44,24.198l19.79,53.105l59.23,40.008l33.34,30.573l52.06-15.145l0.9,1.419
			l1.42-0.901l63.12,11.667l2.31,0.518l0.9,1.418l24.83,35.975l92.88,14.664l2.32,0.518l0.9,1.417L2056.22,334.206z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M1520.8,584.593l118.82-41.613l82.97-0.937l18.35-49.456
			l69.57-6.364l194.62-75.8l45.8-68.88l-1.8-2.836l-24.96-29.92l-92.88-14.663l-1.41,0.9l-0.91-1.418l-25.73-37.394l-60.8-11.148
			l-54.38,14.627l-1.42,0.9l-3.21-1.936l-33.34-30.573l-60.13-41.426l-0.9-1.419l-19.79-53.105l-64.94,7.4l-25.16,41.846
			l-1.94,3.219l-3.22-1.936l-54.09-19.387l-77.32,19.24l-60.23,68.09l17.76,18.574l40.9,67.56l0.9,1.418l-0.51,2.318l-52.45,136.773
			l21.1,36.358l102.55-1.432l3.74-0.382l0.38,3.737L1520.8,584.593z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M1226.23,1026.33l4.16-40.457l67.31-50.689l-26.75-54.659
			l20.91-39.146l37.26-19.671l63.25,5.611l10.22-40.316l-63.62-31.251l-47.5-43.474l10.63-58.483l27.21-29.217l80.63,20.448
			l103.77-55.931l-0.9-1.419l-0.9-1.418l-2.26-66.228L1408,522.88l-2.32-0.518l-1.8-2.837l-21.48-40.094l-0.9-1.419l0.52-2.318
			l51.02-135.873l-40-66.142l-37.32-39.983l-41.74-9.323l-9.29,19.831l-1.42,0.901l-30.03,9.114l-1.42,0.9l-163.98-23.247
			l-15.73,15.96l18.25,38.158l0.9,1.418l3.43,55.535l16.34,19.474l41.6,15.379l2.32,0.518l-0.52,2.318l3.45,33.633l-0.52,2.318
			l0.9,1.418l-13.55,22.533l-1.94,3.219l-2.32-0.518l-59.38-12.049l-20.48-0.925l-21.65,9.768l-18.33,27.552l-0.51,2.319
			l-2.32-0.518l-33.5-2.615l-2.31-0.518l-0.91-1.418l-28.81-45.385l-36.061-12.925l-23.59,12.987l-26.851,54.857l9.366,39.823
			l59.224,40.008l34.395,4.033l116.237-30.019l14.07-24.852l11.1-16.995l1.93-3.218l2.32,0.517l47.27,11.778l4.64,1.036l-3.36,4.12
			l-9.04,29.623l-1.42,0.901L1149,559.954l13.87,46.915l1.8,2.836l-2.83,1.801l-18.44,11.704l-1.42,0.901l-39.68,3.305
			l-38.85,70.434l3.27,83.494l18.64,41.895l0.9,1.418l-1.94,3.219l-37.66,37.838l14.66,32.485l15.56,33.903l85.82-24.641l2.32,0.518
			l2.32,0.518l36.31,22.717l0.9,1.418l0.9,1.418l5.9,28.095l0.9,1.418l-14.85,39.277L1226.23,1026.33z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M1113.22-784.439l1.37,42.906l6.15,37.887l0.9,1.418
			l-10.03,115.819l-33.51,144.654l-0.52,2.319l-53.55,81.755l19.29,33.522l53.83,31.498l3.22,1.936l-0.52,2.318l-9.21,57.583
			l25.85,53.241l0.9,1.419l0.9,1.418l-5.06,39.034l-0.52,2.319l-1.42,0.9l-69.1,47.852l18.89,51.687l0.9,1.418l-0.52,2.319
			l-18.08,37.344l-1.93,3.219l-2.32-0.518L990.059-3.785l0.814,89.032l0.9,1.418l-2.836,1.801l-61.629,47.088l-12.984,101.772
			l29.737,24.9l38.131,3.65l81.818-12.148l21.66-9.768l17.67-19.178l2.83-1.801l2.32,0.518l162.57,24.147l27.71-9.632l9.29-19.831
			l0.52-2.319l4.64,1.036l45.47,8.941l2.32,0.518l0.9,1.419l16.86,17.155l57.91-68.609l1.42-0.901l1.42-0.9l79.64-18.722l1.42-0.9
			l0.9,1.418l53.19,17.969l23.74-40.946l1.42-0.9l1.42-0.901l68.68-7.782l21.02-23.297l-37.44-55.831l-0.9-1.418l-10.74-82.73
			l0.52-2.319l1.42-0.9l106.74-63.787l-2.75-85.813l-52.16-22.606l-0.9-1.418l-0.9-1.419l-21.48-40.094l-34.19-75.798l-100.62-1.788
			l-1.42,0.9l-0.9-1.418l-32.57-23.099l-0.9-1.418l-0.39-3.738l9.09-73.43l-34.1-38.047l-61.9-66.166l-2.32-0.518l0.51-2.319
			l3.42-69.829l-36.53-54.413l-100.04-69.816l-91.86,2.603l-2.32-0.518l-0.9-1.419l-40.16-38.182L1113.22-784.439z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#04624F" d="M1317.46-1481.25l-38.8,26.62l-25.73,87.98l-1.42,0.9
			l-50.66,36.14l-1.42,0.9l-2.32-0.51l-34.13-16.15l-37.36,129.19l17.86,56.33l0.9,1.41l-1.42,0.9l-37.5,135.25l8.41,229.477
			l33.36,8.67l1.42-0.9l0.9,1.418l38.74,39.083l91.86-2.603l2.32,0.518l102.36,70.335l0.9,1.418l38.33,57.249l0.9,1.419
			l-3.41,69.829l59.58,65.648l36.42,38.565l2.32,0.518l-0.52,2.318l-10.5,74.332l30.25,22.581l102.04,0.887l2.83-1.8l0.39,3.737
			l35.09,77.216l21.48,40.094l49.84,22.089l49.51-47.36l-3.17-45.743l-0.38-3.736l3.73-0.383l54.25-8.572l31.2-19.806l8.91-23.569
			l0.52-2.318l1.42-0.901l29.12-10.532l21.3-35.408l2.85-23.705l0.52-2.318l1.42-0.9l52.85-29.575l-6.66-35.568l-2.19-6.574
			l6.96,1.554l57.06,11.531l82.34-14.467l37.12-13.616l1.42-0.9l0.9,1.418l98.75,64.661l120.52,70.741l166.06,13.973l39.88-75.072
			l-17.22-42.795l-0.9-1.418l0.51-2.319l22.99-48.419l1.41-0.901l1.42-0.9l46.66-23.655l-40.05-147.699l-0.9-1.419l1.42-0.9
			l29.19-76.243l0.52-2.318l1.42-0.901l68.94-19.893l1.42-0.9l111.31,2.959l122.34-73.691l1.42-0.9l122.38,7.868l64.67-120.655
			l0.52-2.318l2.31,0.518l75.88,3.564l53.64-44.008l47.23-69.78l-7.57-36.98l-99.85,5.68l-2.32-0.52l-0.9-1.41l-58.53-92.19
			l-70.97-14.64l-24.49,11.57l-1.42,0.9l-2.32-0.52l-84.48-35.91l-57.24,38.33l-1.42,0.9l-2.32-0.52l-90.04-16.46l-33.58,85
			l0.9,1.41l-1.42,0.9l-84.81,41.91l-1.42,0.9l-107.71,2.72l-2.32-0.52l-87.56-43.91l-148.7,22.77l-1.42,0.9l-132.29-23.47
			l-95.01,60.32l-4.25,2.71l-1.29-5.16l-37.3-61.89l-43.92-15.89l-0.9-1.42l-60.98-86.65l-76.76-26.89l-143.47,81.14l-1.42,0.9
			l-2.32-0.52l-35.29-5.45l-2.32-0.52l-0.9-1.41l-38.34-57.25l-84.64-7.96l-129.67,68.4l-2.84,1.8l-0.9-1.42l-79.96-50.72
			l-2.31-0.52l0.51-2.32l6.03-103.32L1317.46-1481.25z"/>
                    <path fill="#04624F" d="M4614.31-2477.9l-0.52,2.32l-3.74,0.38l-57.2-5.48l-92.17,58.52l-2.7-4.25l-26.75-54.66l-0.9-1.42
			l12.47-99.45l-10.51-51.04l-49.35-2.5l-2.31-0.52l-30.62-48.22l-50.49-13.71l-2.32-0.52l-0.9-1.42l-27.24-74.24l-52.56-4.44
			l-4.64-1.04l1.04-4.64l15.55-91.46l-175.63-232.76l-121.31-56.31l19.87-34.51l35.93-106.38l0.51-2.32l1.42-0.9l47.56-22.24
			l-33.02-86.49l-0.9-1.42l1.42-0.9l0.56-46.12l-21.35-46.15l-0.9-1.42l-0.9-1.42l46.26-130.85l-69.43-21.6l-2.31-0.51l-46.83-73.76
			l-149.13-62.52l-1.42,0.9l-0.9-1.42l-45.94-50.43l-46.24-16.42l-0.9-1.41l-0.9-1.42l-16.97-33l-34.33,55.62l-28.29,77.66
			l-60.75,70.41l2.64,69.96l-45.41-74.65l52.3-256.08l-13.06-208.61l-46.74-161.37l-205.56-60.53l-112.41,67.39l-3.17,79.62
			l-40.88,35.91l-25.39-115.22l-97.96,46.27l2.76-61.45l-85.02-11.69l-39.34,50.85l-3.78,169.55l62.56,57.79l-81.48,59.7
			l15.82,21.79l62.34,26.09l-69.99,24.53l-13.7,50.5l-77.59,31.35l-29.74-24.9l-98.36,64.44l38.33,57.25l-110.63,92.12
			l-39.41,116.56l-62.91,41.94l-19.27,69.94l31.86,97.18l83.83,44.29l-49.66,53.41L2331-3175.44l-92.56,54.78l5.48,68.17
			l77.68,131.76l91.84,19.3l69.79,47.24l-79.42,50.41l-149.1-84.43l-49.25,35.25l5.02,4.77l101.97,66.6l5.53,2.46l-4.25,2.7
			l-42.81,39.12l26.09,63.03L2399.5-2610l0.9,1.42l0.9,1.42l16.57,51.17l0.9,1.41l-0.52,2.32l-74.7,111.11l20.55,60.58l122.48,45.62
			l89.18-28.76l1.42-0.9l2.32,0.52l75.93,85.12l3.85,15.46l16.28,85.19l0.9,1.42l-2.84,1.8l-56.88,63.97l52.79,36.13l2.32,0.52
			l-0.52,2.32l20.15,78.75l101.23,37.22l0.9,1.41l69.56,140.91l84.1,32.17l0.9,1.42l0.9,1.42l42.41,104.41l98.17,7.33l1.41-0.9
			l0.9,1.41l54.57,60.88l0.9,1.42l-0.52,2.32l-6.81,117.75l40.25,75.94l0.9,1.41l-17.82,172.51l56.73,89.35l100.75-4.27l3.74-0.38
			l0.38,3.73l8.85,42.15l-0.52,2.32l-1.42,0.9l-47.22,69.78l-0.52,2.31l-52.22,43.108l78.14,69.792l3.22,1.937l-0.52,2.318
			l-5.2,45.089l28.82,45.386l194.36-85.593l1.42-0.9l3.22,1.937l118.59,73.96l51.81-24.938l2.84-1.801l2.7,4.255l22.38,41.513
			l0.9,1.418l-0.52,2.319l-30.77,105.102l37.19,46.038l62.6,13.986l69.85-18.476l5.15-1.282l0.39,3.736l11.68,40.342l0.9,1.418
			l0.9,1.418l-33.2,88.736l36.27,66.524l0.9,1.418l17.6,46.533l123.74,72.677l2.32,0.518l0.38,3.737l-11.25,44.954l0.9,1.418
			l-1.42,0.9l-16.9,26.652l79.69,62.836l53.88-34.212l48.87-38.985l1.42-0.9l48.44,1.085l3.74-0.382l0.38,3.736l7.3,49.098
			l76.1,35.26l2.32,0.517l41.83,47.075l54.45,45.027l30.37,38.43l0.9,1.418l-1.94,3.22l-28.65,52.02l22.51,35.457l0.9,1.419
			l6.01,43.942l-0.52,2.319l-1.41,0.9l-36.51,49.048l9.59,71.519l112.21,4.378l138.43-64.009l25.59-81.917l1.42-0.9l25.28-25.998
			l-17.49-30.684l-0.9-1.418l0.51-2.319l27.37-57.176l-23.54-30.82l-1.8-2.837l2.83-1.8l27.73-31.536l-18.88-51.687l0.51-2.319
			l0.52-2.318l161.16-122.221l-16.7-45.114l-148.14-23.358l-2.32-0.518l-26.11-41.13l2.84-1.801l29.93-46.866l1.42-0.9l55.17-29.057
			l-93.11-171.724l-0.9-1.418l-7.38-86.849l0.51-2.318l1.42-0.9l51.32-44.523l-19.79-53.106l-116.67-55.275l-2.32-0.518l0.52-2.319
			l-9.14-133.491l-0.9-1.42l2.83-1.8l126.86-88.5l5.74-69.31l1.55-6.96l5.02,4.77l117.17,74.86l21.16-29.35l-45.29-58.8l-0.91-1.42
			l0.52-2.32l38.87-217.7l-26.61-60.72l-2.32-0.51l48.26-74.42l3.22,1.93l73.51,46.86l78.34-1.98l-16.05-53.48l-0.9-1.42l0.51-2.32
			l11.39-51.01l-37.07-30.19l-33.77,9.49l-3.73,0.39l-1.81-2.84l-19.67-37.26l-0.9-1.42l0.52-2.31l12.82-73.82l-29.6-30.95
			l-71.26,19.37l-3.74,0.38l-0.9-1.41l-53.03-45.93l-0.91-1.42l0.52-2.32l3.15-57.72l-56.66-29.69l-3.22-1.94l1.94-3.22l43.51-91.3
			l-9.31-105.53l-43.87-59.71l-57.17-27.38l-2.32-0.51l0.51-2.32l-11.77-78.09l-35.28-27.36l-38.85-54.93l-1.8-2.84l1.42-0.9
			l36.64-55.1l-0.9-1.42l46.17-43.24l6.48-39.93l-33.75-12.41L4614.31-2477.9z"/>
                    <path fill="#04624F" d="M1039.19-703.608l-1.42,0.901l-2.32-0.518l-159.863-19.893l-24.781,45.583l-1.418,0.901l-110.723,54.377
			l-0.9-1.419l-91.997,8.659l-1.418,0.9l-109.737-31.819l-87.651,43.709l14.613,76.292l106.765,39.674l2.319,0.519l0.9,1.418
			l37.954,53.512l0.9,1.418l-0.517,2.319L538.68-326.141l20.71,32.62l39.932,6.487l4.637,1.036l-4.566,58.619l-8.034,46.89
			l22.177,113.278l0.9,1.418l-9.069,51.527l11.663,62.245l0.901,1.418l-10.105,56.164l-0.517,2.319l-1.418,0.9l-30.163,15.17
			l43.222,68.077l31.197-19.807l2.837-1.8l34.217,53.895l69.868-40.379l2.837-1.801l1.8,2.837l29.467,37.011l32.864-10.915
			l1.418-0.9l2.319,0.518l16.86,17.155l21.363,24.246l18.931,7.881l21.023-23.297L920.353,134l0.517-2.319l1.418-0.9l60.729-48.507
			l-0.296-91.35l-1.284-5.155l5.538,2.454l50.485,13.714l16.14-34.125l-18.89-51.687l-1.8-2.837l2.84-1.801l69.62-50.171
			l4.55-36.715l-27.27-52.341l-0.9-1.418l1.42-0.901l7.79-56.682l-51.51-30.98l-1.41,0.9l-0.9-1.418l-20.58-38.676l-1.8-2.837
			l1.42-0.9l54.06-84.074l32.99-142.336l10.04-115.819l-4.87-32.731L1039.19-703.608z"/>
                    <path fill="#FFFFFF" d="M981.989,668.181c0.1-0.822-0.485-1.57-1.308-1.67l-13.401-1.632c-0.822-0.1-1.57,0.485-1.67,1.308
			c-0.1,0.822,0.485,1.57,1.308,1.67l11.912,1.451l-1.451,11.912c-0.1,0.822,0.485,1.57,1.308,1.67c0.822,0.1,1.57-0.485,1.67-1.308
			L981.989,668.181z M600.001,545.548c2.777,86.353,65.478,143.003,143.602,165.17c78.099,22.16,171.987,10.003,237.822-41.537
			l-1.85-2.362c-64.965,50.86-157.827,62.953-235.153,41.013c-77.301-21.933-138.7-77.733-141.423-162.38L600.001,545.548z"/>
                </g>
            </g>
            <NavLink to={"/dashboard"}>
                <path className={"chel_area"} fill="#E1FFF9" d="M1197.75,1007.98c-3.08,0-6.02-0.14-9.1,0c-1.82,0.14-2.8-0.56-2.24-2.38c0.98-3.37-2.1-4.07-3.64-4.5
	c-1.82-0.56-4.2-0.42-4.62-2.241c-0.56-2.387,0-4.913,0.14-7.44c3.64-1.965,2.24-6.035,3.64-8.983c0.98-1.965,1.4-3.509,3.78-4.07
	c1.82-0.421,3.64-1.544,5.18-2.667c1.82-1.263,4.06-2.246,4.06-5.053c0-0.562,0.84-1.685,1.26-1.825
	c4.2-0.561,6.02-3.649,7.56-6.878c0.42-0.982,1.12-2.386,0.84-3.088c-2.8-5.754-0.56-11.79-1.26-17.685
	c-0.28-2.246,1.26-5.053-1.26-6.457c-2.24-1.263-4.9-2.105-7.42-2.386c-3.5-0.421-7-0.281-10.5,0
	c-4.34,0.281-7.98-0.842-10.92-4.071c-0.84-0.982-2.24-1.263-3.36-2.105c-1.69-1.263-3.09-1.263-4.91,0.14
	c-3.5,2.667-7.28,5.053-10.92,7.439c-2.38,1.404-3.5,1.123-3.92-1.543c-0.28-1.966-0.56-2.948-2.66-2.808
	c-1.68,0.141-3.22,0.281-4.9,0c-2.38-0.28-3.92,0.702-5.32,2.386c-2.38,2.667-2.38,2.667-4.48,0.702
	c-2.1-2.105-4.48-4.07-6.44-6.456c-2.38-3.088-5.46-4.071-9.1-3.51c-1.12,0.141-2.38,0.983-3.36,0.843
	c-1.26-0.281-2.24-1.404-3.36-1.825c-2.66-1.123-3.78-0.421-3.78,2.386c0,1.684,0,3.228,0,4.913c0,0.702,0.28,1.684,0,2.105
	c-5.18,6.597-1.82,14.177-2.8,21.335c-0.14,0.983-0.42,2.246-1.12,3.088c-3.36,4.211-4.62,4.211-8.82,0.141
	c-1.41-1.404-3.23-2.808-3.93-4.632c-0.98-2.527-2.38-2.808-4.62-2.948c-3.22-0.281-5.04-2.667-5.18-5.895
	c-0.14-1.544,0.14-3.228-0.28-4.772c-0.56-1.825-1.12-4.211-2.52-5.194c-2.66-1.684-5.88-2.667-8.96-3.649
	c-1.96-0.562-3.36,0.982-3.5,2.386c-0.28,2.386-1.82,1.965-2.94,1.684c-1.54-0.561-3.64-1.403-4.06-2.667
	c-0.7-2.245,0.98-5.614-2.94-6.316c0-2.527-0.28-5.193,0.14-7.72c0.28-1.544,0.56-3.79,3.5-1.684c1.96,1.403,3.5,0.14,3.36-2.246
	c0-2.948,0.42-5.755-1.26-8.562c-0.98-1.685-1.54-1.965-2.8-0.842c-1.12,0.842-1.4,0.701-1.54-0.702c0-0.421-0.28-1.123-0.56-1.123
	c-1.96-0.421-3.36-1.123-3.64-3.369c-0.42-2.807,0.28-5.474,1.68-7.72c2.1-3.368,4.06-7.018,3.78-9.544
	c-0.28-2.667-1.26-5.194-1.26-7.861c0-2.667-1.26-4.632-2.1-6.877c-2.1-5.334-1.26-10.808,1.54-15.44
	c1.54-2.527,2.24-5.194,3.08-7.861c0.7-1.965,1.4-3.509,3.5-3.368c2.8,0.14,2.38-1.685,2.1-3.229c-0.14-1.263-1.12-2.386-1.26-3.649
	c-0.56-5.615-0.56-5.615,5.04-5.895c3.36-0.141,6.44-2.106,9.8-1.685c0.14,0,0.42-0.701,0.42-1.123c0-0.421-0.14-0.982-0.42-1.122
	c-0.84-0.562-1.82-1.123-2.94-1.544c-5.04-1.685-4.9-1.685-5.04-6.738c-0.28-6.597-1.12-13.194-1.26-19.791
	c0-2.386-2.66-1.404-2.66-2.246c0.28-4.491-2.1-9.123,1.4-13.194c0.7-0.702,0.98-1.965,0.98-3.088c0-13.755,0-27.651,0-41.407
	c0-1.403-0.14-2.245-1.82-2.105c-1.82,0.14-3.78,0-5.6,0c-2.24,0-3.08-1.404-2.38-3.509c0.14-0.421,0.98-0.702,0.98-0.983
	c-0.98-6.035,3.78-8.843,6.86-12.632c2.24-2.808,4.34-5.755,2.24-10.106c-1.26-2.387,0-5.755-1.54-8.422
	c-0.7-1.263-0.14-2.948,1.26-4.351c3.08-3.369,3.64-7.44,2.24-11.791c-1.68-5.053-1.4-9.825,2.52-13.755
	c0.84-0.843,0.84-1.404-0.14-1.965c-0.14-0.141-0.42-0.281-0.56-0.562c-3.22-4.491-2.94-6.878,1.54-9.685
	c0.7-0.421,2.52,0,3.22,0.702c1.68,1.544,3.08,1.965,4.48-0.14c1.54-2.246,3.92-1.544,5.74-1.264c5.32,0.842,7.85-1.965,10.09-6.176
	c3.08-5.474,6.16-10.948,8.4-16.843c0.7-2.106,1.82-3.93,1.82-6.316c0-1.544,1.26-3.088,2.1-4.632c1.26-2.246,2.66-4.492,3.92-6.738
	c1.54,2.807,5.04,0,6.72,2.386c4.9,0.141,9.66,0.421,14.42,0.141c3.5-0.281,10.5,0.561,13.3,2.526c1.82,1.263,3.64,2.527,5.32,3.93
	c1.12,0.983,1.4,0.562,1.54-0.701c0.42-5.615,2.8-10.528,5.88-15.16c2.1-3.088,1.54-6.877,0.28-10.386
	c-1.54-4.071-4.2-6.738-8.26-8.282c-2.24-0.842-1.82-2.526-0.56-4.211c0.84-1.123,1.54-2.245,2.52-3.088
	c2.52-2.245,3.22-5.474,3.64-8.281c0.28-2.105-1.54-4.632-2.8-6.737c-0.84-1.404-2.1-2.527-3.36-3.509
	c-1.96-1.404-1.96-3.51-1.4-5.053c1.82-4.211,2.94-8.703,7.56-11.651c4.76-3.088,8.4-8,12.47-12.211c0.28-0.281,0.7-0.702,0.7-1.123
	c0.42-3.79,1.82-7.158,3.64-10.387c1.82-3.228,1.12-7.299,3.22-10.667c0.7-0.983,0.14-2.808,0.14-4.211c0-8.562,0-8.562,4.2-15.019
	c0.56-0.702,1.26-1.404,1.82-2.105c0.7-0.702,1.4-1.544-0.28-1.544c-3.36-0.141-7-0.983-9.38,2.807
	c-1.12,1.825-2.8,2.105-4.76,0.842c-0.7-0.421-1.68-0.842-2.39-0.702c-3.22,0.562-5.6-0.842-7-3.228
	c-1.54-2.667-4.48-1.825-6.44-3.369c-1.68-1.403-3.5-2.807-5.32-3.93c-1.54-0.983-4.2,0.281-3.92,1.825
	c0.84,5.474-1.68,7.86-6.58,9.123c-1.54,0.421-2.1,2.106-1.96,4.071c0.28,5.193-1.96,9.123-5.32,13.334
	c-2.52,3.229-5.74,5.474-8.68,8.001c-2.8,2.526-5.6,5.053-9.1,6.597c-1.4,0.561-2.52,1.965-2.1,3.93c0.14,1.123,0.14,2.386,0,3.509
	c-0.28,1.965-2.52,3.509-4.48,3.65c-4.62,0.561-8.82-1.825-13.3-1.825c-1.97-2.667-5.47,0.421-7.57-1.965
	c-0.14-0.141-0.7-0.141-0.98,0c-3.78,2.807-7.56,5.474-11.2,8.421c-2.24,1.966-4.48,3.65-7.56,3.65c-1.26,0-2.52,0.702-3.64,1.404
	c-2.38,1.544-4.9,2.947-7.56,3.789c-3.78,1.264-5.46,4.071-5.18,7.58c0.42,3.93-1.82,5.334-4.62,6.457
	c-2.52,0.842-5.04,1.403-7.56,1.824c-1.68,0.281-2.94-0.28-4.2-1.544c-3.92-4.07-7.84-8-11.9-11.931
	c-0.98-0.982-1.96-1.965-2.94-2.947c-4.07-3.79-6.45-3.65-10.65,0.14c-0.56,0.421-0.98,0.983-1.54,1.544
	c-3.08,3.79-6.998,5.615-11.898,5.053c-3.081-0.421-4.901,0.421-6.861,3.65c-3.781,6.035-8.821,11.369-13.722,16.843
	c-2.52,2.807-4.2,2.667-7.561,0.421c-5.041-3.368-9.521-7.158-12.042-13.194c-0.84-2.105-3.78-3.93-6.3-4.772
	c-2.801-0.983-3.501-2.527-3.361-5.053c0-0.702-0.14-1.404,0-2.106c0.84-4.912-1.68-7.72-5.601-9.825
	c-1.68-0.983-3.36-1.965-5.04-2.948c-3.081-1.965-3.501-5.614-4.621-8.562c-1.4-3.649-1.26-3.649-5.321-2.526
	c-0.84,0.281-1.82,0-2.66,0.281c-2.66,0.701-4.621-0.281-4.761-2.527c0-0.421,0-0.983,0-1.404c-0.14-4.491-1.26-6.035-5.74-5.895
	c-3.921,0-7.141-1.684-9.662-3.79c-3.64-3.088-7.981-4.772-11.621-7.579c-0.7-0.562-1.82-0.983-1.96-1.685
	c-1.401-4.912-4.481-8.702-7.982-12.071c-1.12-0.982-0.28-1.403,0.281-1.965c1.12-1.403,1.82-3.088,3.08-4.351
	c2.94-3.088,4.901-6.878,7.561-10.247c1.82-2.526,0.28-3.228-0.98-4.491c-0.84-0.702-1.82-1.123-2.801-1.544
	c-3.64-1.544-4.06-2.106-3.36-5.193c0.14-0.562,0.98-1.404,0.98-1.404c-4.06-2.807-2.24-8.422-5.741-11.791
	c-2.66-2.526-0.56-8.842,2.801-11.229c2.38-1.544,4.9-2.947,7.141-4.632c1.82-1.403,2.52-2.947,0.98-5.333
	c-1.54-2.386-2.521-5.053-3.361-7.72c-0.14-0.702,0.7-1.965,1.12-2.106c2.801-0.561,2.941-0.421,3.081-2.526
	c0.28-4.211,1.82-5.474,5.461-3.93c1.68,0.701,2.38,0.701,3.5-0.421c0.56-0.702,1.54-1.264,1.96-1.965
	c2.801-6.598,9.102-8.703,14.702-11.651c3.501-1.824,7.141-0.561,9.802,1.966c3.5,3.509,7.561,4.491,12.181,4.491
	c6.581,0,13.022-0.14,19.603,0c3.78,0.14,7.701,0.281,11.201,1.544c2.241,0.842,3.781,3.509,5.601,5.615
	c0.7,0.842,0.84,1.965,1.4,2.947c1.541,2.386,3.081,4.492,6.441,4.071c1.12-0.141,2.521,0.561,3.641,1.123
	c0.28,0.14,0.56,1.263,0.42,1.403c-2.661,2.246-3.921,5.334-5.601,8.282c-1.26,2.386-4.061,3.368-7.001,3.228
	c-1.82-0.14-2.38,0.421-2.38,2.246c0,3.228,1.12,7.299,3.64,11.79c0.14,0.141,0.42,0.281,0.42,0.562
	c0.28,5.895,0.98,11.93-1.82,17.405c-0.98,1.965-3.78,2.947-5.741,4.632c-2.8,2.245-2.24,5.053,1.12,7.018
	c1.541,0.842,3.641,2.526,4.901,2.105c2.24-0.702,4.901-2.386,5.741-4.351c0.98-2.246,1.12-2.386,3.22-1.263
	c2.381,1.263,2.661,0.842,2.661-1.685c0-0.982,0.14-2.526-0.14-2.667c-4.201-1.123,0.42-5.474-2.661-7.018
	c0.42-0.702,0.56-1.684,1.12-1.965c1.261-0.842,2.941-0.982,4.061-1.965c1.12-0.842,2.94-1.965,0.98-3.93
	c-0.28-0.281,0-0.983,0.14-1.404c0.98-1.544,1.96-2.947,2.94-4.351c0.281-0.561,0.281-1.263,0.281-1.825
	c-0.561,0.141-0.981,0.141-1.541,0.281c-3.5,0.983-3.5,0.983-4.06-2.667c-0.14-0.842-0.28-1.825-0.84-2.386
	c-1.401-1.544-0.28-2.526,0.84-3.369c3.64-2.807,8.121-4.351,11.761-7.298c0.98-0.843,3.078-0.281,4.618-0.141
	c0.28,0,0.56,0.702,0.98,0.983c2.1,1.123,5.04,1.824,6.02,3.649c1.12,1.965,0.28,4.913,0.56,7.439c0,0.702,0.84,1.544,1.4,1.685
	c3.36,0.842,2.52,6.035,6.59,6.035c0.14,0,0.42,1.264,0.42,1.825c0,1.404,0,2.807,0,4.211c0.14,1.544-0.14,4.07,0.7,4.491
	c2.1,1.123,2.8-1.684,4.2-2.526c0.7-0.421,1.54-0.983,2.24-1.404c2.66-1.544,2.66-1.544,2.66,1.404c0,1.123,0.14,2.386,0,3.509
	c-0.7,3.79-0.42,3.369,2.66,5.053c2.66,1.404,5.46,0.983,8.12,2.386c2.24,1.123,5.6,0.702,7.98-2.246
	c2.38-2.947,4.9-5.754,6.44-9.264c0.84-1.684,2.52-3.088,3.92-4.491c-1.68-1.123-3.5-2.106-4.76-3.509
	c-0.98-0.983-1.26-2.667-1.96-4.071c-0.84-1.544-1.68-3.088-2.52-4.632c-2.24-4.211-7-4.632-10.5-4.912
	c-3.92-0.141-3.64-2.246-4.34-4.352c-0.28-0.561-0.7-1.122-0.84-1.824c-0.14-1.404-0.28-2.948,1.96-2.667
	c0.56,0.14,1.54,0,1.82-0.421c1.68-1.965,3.22-0.842,3.78,0.561c1.26,3.088,2.38,3.229,5.46,1.685c3.36-1.685,1.54,0.701,0.84,1.965
	c-0.14,0.421,0.14,1.544,0.7,1.684c0.42,0.281,1.54,0,1.82-0.421c1.54-1.825,3.22-3.79,4.48-5.895c1.12-1.965-0.56-4.352-3.22-4.913
	c-5.04-0.983-5.04-0.983-5.04-6.036c0-0.982,0-1.824,0-2.807c0.14-4.632,0.56-5.474,5.04-5.193c3.78,0.28,7.56-1.123,11.34,1.123
	c2.24,1.403,5.18-1.544,7.84,0.14c0.14,0,0.56-0.281,0.56-0.421c0-0.281-0.14-0.842-0.42-0.983c-0.7-0.561-1.4-1.263-2.24-1.403
	c-1.4-0.281-2.8,0-4.2-0.141c-0.98-0.14-2.38,0.702-2.66-1.123c-0.28-1.684,0.84-4.21,2.52-4.351c2.38-0.14,5.04-0.561,7.14,1.404
	c4.76-1.544,4.06,3.228,5.74,5.193c0.28,0.421-0.14,1.404,0.14,2.106c0.42,1.263,1.12,2.386,1.68,3.509
	c0.84-0.842,1.68-1.544,2.52-2.386c0.7-0.702,6.02-5.615,6.86-5.615c1.82-0.14,1.54-1.404,1.54-2.386c-0.14-2.105,0-2.948,2.8-3.369
	c5.19-0.982,7.43-4.912,5.47-10.246c-1.4-3.65-1.96-6.738,0.14-10.106c0.42-0.702-0.14-1.825,0.14-2.808
	c0.14-0.701,0.84-1.824,1.26-1.824c2.38-0.141,4.62,0.14,7-0.141c1.96-0.14,3.78-1.123,5.74-1.263c2.1-0.281,4.62,0.562,6.16-0.281
	c1.26-0.701,1.68-3.368,2.1-5.193c0.56-2.105,1.54-1.825,2.8-0.842c0.56,0.421,0.98,0.982,1.54,1.544
	c4.06,3.93,4.06,3.93,8.26-0.141c1.12-1.122,2.1-2.526,4.06-2.105c3.08,0.561,4.62-1.825,5.74-4.071
	c1.12-2.526,2.24-4.912,4.48-6.597c1.54-1.263,1.68-2.807,0.98-4.351c-0.84-1.965-2.94-2.667-4.76-1.824
	c-3.22,1.684-6.58,1.263-9.94,1.263c-0.42,0-0.98,0-1.4,0c-1.54,0.14-2.1-0.562-1.82-2.106c0-0.28,0-0.421,0-0.701
	c-0.14-1.825,0.28-4.632-0.56-5.194c-1.54-0.982-4.06-0.702-6.02-0.421c-1.12,0.14-3.08,1.544-3.08,2.105
	c0.7,3.79-2.66,3.51-4.34,4.773c-1.82,1.263-3.64,2.386-5.6,3.509c-0.7,0.421-1.96,0.702-2.24,0.421
	c-1.26-0.983-0.28-1.965,0.56-2.807c1.12-1.404,2.24-2.808,3.22-4.352c0.7-1.122,1.4-2.386,1.4-3.649s-0.84-2.386-1.26-3.649
	c-0.56-1.685-0.56-3.65-1.54-4.913c-2.66-3.65-0.84-6.597,0.84-9.825c0.98-1.966,2.24-3.79,2.1-6.176
	c-0.14-3.931-0.84-4.913-4.62-5.053c-3.78-0.141-7.42,0-11.2,0c-0.84,0-2.24-0.422-2.24-0.843c-0.14-0.982,0-2.386,0.56-3.228
	c0.56-0.842,1.82-1.123,2.8-1.544c2.52-1.263,4.2-2.807,3.64-6.036c-0.7-4.351,1.12-8.281,2.24-12.351
	c0.7-2.387,0.84-4.632-0.42-7.159c-1.68-3.79-1.96-8.141-3.78-11.79c-1.12-2.106,0.28-2.808,0.98-2.948
	c2.66-0.842,5.6-1.404,8.4-1.684c1.68-0.141,2.38-0.421,2.38-2.386c0-1.264,0.84-2.527,1.4-3.65c0.7-1.544,2.66-2.948,0.14-4.491
	c-0.28-0.141-0.28-1.123-0.14-1.264c2.24-2.105,1.26-4.772,1.26-7.158c0-1.123,0.98-3.088-0.98-3.509
	c-1.96-0.281-4.2-0.702-5.6,1.684c-0.56,0.983-0.98,1.965-1.54,2.948c-0.56,1.123-1.12,0.28-1.96,0.14
	c-2.24-0.561-4.48-2.246-7.14-0.702c-0.56,0.281-1.96-0.702-2.94-1.123c-0.84-0.421-1.55-1.544-2.11-1.403
	c-6.02,0.28-7.84-4.492-10.5-8.282c-0.7-0.982-0.98-1.544,0.56-1.544c2.52,0,5.18,0,7.7,0c2.1,0,4.49,0.281,6.03-0.701
	c0.84-0.562,0-3.51,0.42-5.194c0.56-2.246,1.4-4.632,2.8-6.457c1.68-2.105,0.14-3.649-0.28-5.193c-0.28-1.123-1.82-2.105-2.8-2.948
	c-2.24-1.965-0.84-4.351-1.12-6.597c0-0.28,0.42-0.842,0.84-0.842c3.78-1.123,7.28-3.509,11.48-2.807
	c1.12,0.281,3.08,1.544,2.94,2.246c-0.42,3.93,1.96,3.228,4.34,3.368c2.94,0.141,6.02-0.982,8.68,0.843
	c0.14,0.14,1.12-0.421,1.12-0.702c0-1.825,1.26-1.685,2.38-1.685c3.92,0,7.98,0,11.9,0c1.4,0,2.94-0.28,2.8,1.966
	c0,0.14,2.38,1.122,2.94,0.701c2.52-2.105,5.32-1.263,7.84-1.263c6.58-0.14,6.86,0.14,2.94,5.895c-1.82,2.808-3.22,5.755-5.04,8.422
	c-0.7,1.123-0.84,2.527,0.84,2.386c2.66-0.14,4.76,1.123,6.86,1.965c2.8,1.123,5.46,1.825,8.12-0.561
	c2.11-1.825,4.77-3.088,7.15-4.492c2.38-1.544,4.48-1.544,6.72,0.562c1.54,1.544,3.5,2.807,5.32,3.93
	c1.12,0.702,2.38,1.403,3.5,1.544c4.2,0.561,8.4,1.263,12.74,1.403c3.36,0.141,6.86,0,9.8,1.544c3.08,1.685,4.48,2.106,4.2-2.245
	c0-0.702,2.1-2.106,3.22-1.965c4.76,0.421,9.66-1.544,14.14,1.122c0.7,0.422,2.1-0.14,3.22-0.421c0.84-0.14,1.54-0.561,2.38-0.982
	c5.19-2.386,7.99-2.106,10.23,2.386c1.4,2.807,2.52,1.965,4.06,1.404c1.96-0.702,4.34-1.544,5.74-2.948
	c2.66-3.088,5.88-2.105,8.96-2.105c1.54,0,0.7,3.649,3.64,2.807c1.68-0.562,3.78-0.281,5.6,0c1.68,0.281,3.36,0.842,5.04,1.403
	c1.82,0.702,3.64,1.544,5.32,2.387c1.4,0.701,2.52,1.684,3.92,2.386c2.1,1.123,4.34,1.684,6.16-0.562
	c1.96-2.526,3.36-1.544,4.9,0.281c0.42,0.421,1.26,0.983,1.68,0.842c2.94-1.263,6.02-2.526,8.82-4.07
	c0.56-0.281,0.98-1.685,0.7-2.246c-1.12-2.807-2.66-5.474-3.92-8.281c-2.24-4.913-3.5-10.107-2.94-15.581
	c0.28-2.386,1.54-3.649,4.06-3.368c1.82,0.28,3.78,1.123,5.46,1.824c2.53,1.123,4.91,2.948,7.57,3.509
	c4.76,1.123,5.04,0.702,6.44,2.106c0.98,1.123,1.26,0.14,2.1-0.281c1.96-1.263,4.2-2.386,6.3-3.509c1.26-0.702,2.8-2.386,3.78-2.105
	c1.82,0.421,3.36,1.965,4.9,3.368c2.94,2.527,5.88,5.053,8.68,7.72c1.4,1.263,2.94,1.263,3.5-0.421
	c0.84-2.386,1.96-4.491,4.62-3.649c2.94,0.842,6.86,0.14,8.4,4.211c1.12,3.088,2.66,5.895,3.92,8.842
	c0.56,1.123,0.42,3.369,1.12,3.509c1.4,0.422,3.08-0.14,4.62-0.421c0.84-0.14,1.68-0.842,2.52-0.701c0.7,0,1.68,0.842,1.82,1.263
	c0.14,2.105,0.28,4.211,0,6.316c-0.28,2.527,0.84,3.509,2.94,4.351c6.17,2.527,12.75,4.071,18.63,7.44
	c1.68,0.982,3.78,0.421,5.74,1.263c2.66,1.123,4.2,0.421,2.38,4.351c-1.54,3.228,1.68,6.878,4.48,8.281
	c5.46,2.667,6.58,6.738,6.44,12.072c-0.14,3.088-0.14,6.035,0,9.123c0.14,3.79,0.7,4.071,4.76,3.229
	c1.12-0.281,2.24-0.141,3.36-0.141c3.08,0,3.78,0.702,5.18,4.211c0.84,2.105-1.4,3.228-2.1,3.369c-4.48,0.842-1.54,5.755-4.2,7.439
	c0.84,1.684,1.54,3.649,2.66,5.193c1.82,2.387,3.92,4.632,7.56,3.79c2.24-0.561,3.92,0.421,5.46,2.246
	c0.84,0.982,1.54,2.386,3.36,1.965c1.96-0.281,2.52,1.263,3.5,2.667c3.64,5.334,3.08,11.369,2.52,17.124
	c-0.28,1.965-0.14,3.509,0.56,5.053c0.56,1.263,1.12,2.386,1.96,3.369c0.98,1.263,0.56,1.965-0.28,2.947
	c-0.98,0.983-2.24,1.825-2.94,2.948c-0.42,0.702,1.4,1.825-0.14,2.948c-1.12,0.842-1.54,2.947-2.66,3.368
	c-1.4,0.562-2.24,1.123-1.54,2.246c0.42,0.702,2.66,1.123,2.94,0.702c2.52-2.807,4.2-0.702,5.46,1.123
	c2.38,3.369,4.49,7.018,6.45,10.667c1.26,2.387,0.28,4.071-2.39,3.65c-3.36-0.562-6.16,1.123-9.1,1.825
	c-2.94,0.701-5.88,2.526-4.9,6.877c0.42,1.965-0.28,3.088-2.52,2.808c-4.76-0.702-7.98,3.088-12.18,4.351
	c-3.08,0.842-4.2,1.123-4.76-1.685c-0.28-1.965-0.56-2.947-2.66-2.807c-2.38,0.141-4.62-0.14-7,0.141
	c-0.98,0.14-2.24,0.982-2.66,1.824c-0.28,0.702,0.28,1.965,0.84,2.808c1.82,2.526,4.06,4.912,5.88,7.439
	c0.7,0.982,0.98,2.386,1.26,3.649c0.28,1.544,0,2.667-2.1,2.527c-1.68-0.141-3.22,0.14-4.9-0.141c-2.1-0.28-2.52,0.842-3.22,2.386
	c-1.12,2.527-2.94,5.054-6.3,4.492c-3.36-0.421-3.22,1.544-3.08,3.65c0,0.701,0.14,1.403,0,2.105c-0.28,2.105,0.56,2.526,2.66,2.807
	c1.96,0.421,3.92,1.825,5.6,3.229c2.52,2.105,4.34,5.333,8.26,5.474c0.84,0,2.1,1.123,0.56,2.947c-2.38,2.667-2.24,4.071-0.28,7.159
	c0.56,0.842,0.98,1.965,1.4,2.947c0.84,2.246,1.54,4.071,3.92,5.334c2.52,1.263,2.38,3.509,0.28,5.615
	c-0.28,0.28-0.56,0.842-0.98,0.982c-4.9,2.246-6.02,6.457-6.02,11.37c0,0.842-0.56,1.544-0.84,2.386
	c-0.84-0.421-1.54-0.702-2.38-1.123c-0.42-0.281-0.84-0.562-0.98-0.983c-1.4-3.509-4.48-4.491-7.56-3.368
	c-4.06,1.403-7.98,1.263-12.04,1.403c-0.56,0-1.54,1.404-1.4,1.544c2.94,4.632,1.96,10.247,3.92,15.159
	c0.7,1.685,0.98,3.369,2.8,4.211c3.08,1.263,4.34,3.229,4.62,6.738c0.14,1.965,2.1,3.789,3.08,5.614
	c0.7,1.404,1.82,3.088,1.54,4.351c-0.56,1.965-0.56,4.492-2.8,5.896c-1.82,1.122-5.32,5.895-5.88,7.158
	c-0.56,1.123-1.68,2.527-1.26,3.369c0.42,0.982,2.38,1.123,3.22,2.105c1.26,1.544,3.78,2.246,3.36,4.913
	c-0.42,2.807,0.7,3.79,3.36,3.369c2.66-0.422,5.88,1.263,7.98-0.983c1.96-2.105,2.52-1.123,4.06,0.14
	c3.36,2.948,7.7,2.808,11.62,1.965c1.96-0.421,3.5-3.368,5.18-5.193c0.7-0.842,0.84-1.965,1.4-2.807
	c0.42-0.562,1.12-1.404,1.68-1.404c4.2-0.14,8.4-0.14,12.6,0c1.4,0,2.94,0.702,4.34,1.404c1.68,0.702,0.28,1.403-0.28,1.824
	c-0.7,0.562-1.68,0.562-2.38,1.123c-2.52,1.685-2.8,3.931-0.7,6.317c0.84,0.842,1.68,1.684,2.52,2.526
	c2.94,2.948,2.94,2.807,6.03-0.982c0.42-0.562,1.82-0.843,2.24-0.562c1.12,0.702,1.96,2.106,3.08,2.807
	c0.84,0.562,2.24,1.264,3.08,0.983c7.56-2.527,15.68-3.93,21.98-9.685c2.52-2.246,5.46-4.351,9.52-1.825
	c1.68,1.123,5.04,1.123,6.72,0.141c5.18-2.808,9.52-0.843,13.86,1.263c1.26,0.702,3.5-1.123,3.92,1.544
	c0.42,2.386,1.12,4.772,1.4,7.158c0.28,2.246,0.14,4.632,0,7.019c0,1.544,0.28,2.666,1.4,3.649c1.54,1.263,3.22,2.526,2.94,5.193
	c-0.14,1.404,1.54,3.088,1.12,4.211c-0.42,1.123-2.52,1.544-3.5,2.667c-1.4,1.684-2.38,3.65-3.36,5.615
	c-0.56,1.122-0.84,2.386-1.4,3.649c-1.12,2.527-2.1,2.386-4.2,0c-0.42-0.421-1.4-0.983-1.54-0.842
	c-0.56,0.561-1.12,1.263-1.26,1.965c-0.28,2.807,0.84,5.334,2.8,7.158c2.1,1.825,1.4,3.088-0.42,4.352
	c-1.12,0.701-2.24,1.403-3.36,1.965c-2.24,1.123-1.68,2.667-0.56,4.211c0.56,0.701,0.98,1.544,1.54,2.105
	c1.68,1.404,1.68,2.667,0,3.93c-1.12,0.842-2.1,1.965-1.54,3.509c0.28,0.562,1.12,0.983,1.68,1.544
	c1.54-2.526,3.36-0.421,4.34,0.141c3.36,1.544,7.98,2.105,7.98,7.579c0,1.123,0.98,2.527,1.96,3.228
	c0.42,0.281,1.82-0.842,2.81-0.982c2.38-0.421,5.6,3.649,5.04,6.036c-0.84,3.088-4.34,6.175-7.57,6.456
	c-2.66,0.281-5.46-0.421-7.84,1.404c-0.42,0.421-1.96,0-2.52-0.562c-1.54-1.544-2.24,0.281-2.38,0.843
	c-0.42,1.263-0.7,2.666-0.56,3.93c0.84,7.018-2.52,11.229-9.38,11.229c-2.24,0-4.62,1.123-6.58-1.123
	c-0.14-0.141-0.42-0.281-0.56-0.141c-5.6,0.983-10.78-1.544-16.24-1.403c-2.94,0-5.46,0.561-6.86,3.79
	c-0.7,1.544-2.24,2.105-4.06,1.965c-2.1-0.141-4.2,0-6.3,0c-4.76-0.281-8.4,2.245-11.91,5.053c-1.12,0.982-1.68,1.824-0.56,3.368
	c0.98,1.404,1.68,3.088,1.96,4.773c0.56,3.088,0.28,6.316-1.82,8.983c-1.96,2.526-2.38,5.193-2.24,8.141
	c0,1.123-0.28,3.088-0.7,3.088c-1.54,0.14-3.22,0.14-4.62-0.421c-0.98-0.421-1.68-1.544-2.52-2.386
	c-0.42-0.421-1.26-0.702-1.54-1.264c-0.98-1.824-2.24,3.79-3.22-0.14c-0.28-1.263-1.12-2.386-1.54-3.509
	c-0.84-2.246-1.82-4.351-2.52-6.597c-0.42-1.123-1.26-2.667-0.84-3.369c0.7-1.263,2.24-2.105,3.5-3.088c0.28-0.28,0.98,0.141,1.4,0
	c1.4-0.561,2.8-1.263,4.06-1.824c-1.68-0.702-3.36-2.106-5.04-2.106c-2.66,0-5.46-0.982-7.98,0.983c-0.84,0.701-2.66,0.28-4.06,0.28
	c-4.2,0-8.4-0.14-12.6,0c-4.34,0.141-8.4-0.28-12.46-2.245c-2.94-1.404-6.3,0.842-6.86,4.21c-0.56,3.369,1.12,6.036,1.96,8.984
	c1.26,4.491,0.56,5.614-4.06,5.895c-5.18,0.281-10.22-0.281-14.99-2.948c-0.56-0.28-1.26-1.824-1.12-1.824
	c2.24-1.825,1.12-4.352,1.26-6.457c0.14-1.263-0.28-2.667-0.42-3.93c-1.54,0.561-3.5,0.702-4.76,1.684
	c-1.26,0.983-1.96,2.948-3.08,4.211c-0.98,1.123-2.24,1.825-2.94-0.421c-0.14-0.562-0.56-1.263-0.98-1.684
	c-0.98-1.264-1.82-3.088-3.08-3.369c-1.12-0.421-2.8,0.842-4.2,1.263c-0.42,0.14-1.26,0.562-1.4,0.281
	c-1.82-1.965-2.94-0.281-3.92,0.842c-0.84,1.263-1.26,2.807-1.96,4.071c-0.56,0.982-1.26,1.965-2.1,2.666
	c-1.12,0.843-3.36-0.421-3.5-1.824c0-1.123,0.14-2.386-0.14-3.509c-0.14-0.702-0.84-1.264-1.54-1.685
	c-1.12-0.561-2.38-1.263-3.64-1.403c-4.06-0.421-5.04-2.808-4.62-6.457c0.14-0.983,0-1.825,0-2.807
	c-0.14-2.106-1.26-3.229-3.36-3.369c-0.42,0-0.98-0.14-1.4,0c-3.22,1.825-7.14,0.702-10.36,1.965c-2.52,0.983-5.74,0.983-6.44,4.772
	c-0.14,0.702-1.96,0.983-2.66,1.685c-0.56,0.421-0.84,1.544-0.7,2.246c0.14,0.842,0.7,1.684,1.26,2.245
	c3.78,4.492,2.66,9.826,2.8,15.019c0,0.421-0.14,1.123-0.42,1.123c-2.8,0.702-5.6,1.123-8.41,1.684
	c-0.56-2.666-4.2-3.649-3.78-6.597c0.56-3.228-1.26-3.368-3.64-3.228c-1.68,0.14-3.22,0.14-4.9,0c-1.54-0.14-2.66,0.421-3.64,1.544
	c-1.96,2.246-5.04,3.369-6.16,6.457c-0.56,1.684,0.28,2.947,2.1,3.228c2.38,0.421,4.76,1.123,7.14,1.404
	c2.24,0.28,4.9-0.562,6.86,0.28c1.26,0.562,1.26,3.509,2.24,5.194c1.54,2.807,3.22,5.614,7.01,5.755c0.42,0,0.98,0.14,1.12,0.421
	c1.26,2.105,0,8.141-2.1,9.264c-4.35,2.386-7.43,5.755-9.67,10.106c-0.84,1.684-1.26,2.947-3.78,2.667
	c-3.64-0.281-3.64,0.14-3.78,4.21c0,1.825,0.28,3.51-1.4,5.054c-0.7,0.701,0.84,2.947-1.4,3.228c-1.82,0.14-3.64,0.561-5.04-1.404
	c-0.84-1.123-1.82-2.386-3.08-2.667c-1.68-0.421-3.92-0.561-5.32,0.281c-0.84,0.421-0.56,2.948-0.56,4.492
	c0,3.509,0,3.789,3.22,3.509c6.44-0.702,12.6,1.544,19.04,1.544c0.56,0,1.54,0.982,1.54,1.544c0.14,2.947,2.24,4.07,4.34,5.474
	c5.61,3.369,11.77,2.807,17.79,2.667c1.12,0,2.66-1.263,2.94-2.246c1.4-4.071,4.2-4.913,7.98-4.632c2.1,0.14,4.2-0.14,6.3,0.14
	c1.96,0.281,3.92,0.562,5.74,1.404c2.24,0.983,0.56,3.369,1.12,4.913c0.84,1.965,1.68,4.07,3.08,5.755
	c1.4,1.544,1.54,2.526-0.14,3.93c-0.7,0.701-0.98,1.965-1.68,2.807c-0.14,0.281-0.98,0.281-1.4,0c-1.4-0.983-1.96-1.123-2.94,0.842
	c-1.68,3.93-1.96,3.93-5.74,5.053c-2.24,0.702-4.62,0.983-7,1.684c-3.36,1.123-5.04-0.28-5.18-4.07
	c-0.14-2.948-1.82-3.93-4.06-4.632c-0.56-3.509-1.96-2.527-3.92-0.983c-1.68,1.264-4.48,1.685-5.46,3.229
	c-1.4,2.105-1.82,5.053-1.82,7.579c0,2.106-0.71,3.65-2.25,4.773c-4.76,3.228-3.78,7.86-2.94,12.352
	c0.14,1.122,0.7,2.105,0.84,3.088c0.42,1.965-1.12,4.491,1.12,5.754c2.94,1.825,5.47,4.632,9.53,3.931
	c1.54-0.281,3.36,0.28,4.9-0.141c4.2-0.842,7.28,1.685,10.64,3.229c2.52,1.263,4.62,3.509,6.86,5.333
	c1.4,1.123,2.1,2.948,4.48,2.667c3.08-0.281,6.44,0.421,9.24-1.544c0.84-0.561,1.68-1.544,2.24-2.526
	c0.56-0.983-0.84-3.088,1.54-3.088c2.24,0,4.62-0.842,6.44,1.544c2.38,3.088,5.6,5.614,7.14,8.983c1.4,2.948,3.64,4.351,6.02,5.755
	c2.24,1.263,4.76,2.246,7,3.368c1.54,0.843,3.09,1.825,5.05,1.404c3.92-0.842,7.56,0.842,11.34,1.404
	c5.46,0.982,10.22,6.877,10.08,12.492c-0.14,3.79,1.12,7.86-1.4,11.369c1.26,3.79-1.82,4.352-4.2,5.474
	c-2.38,1.123-4.62,2.527-7,3.369c-3.5,1.263-6.86,1.825-10.08-1.263c-2.53-2.386-5.75-4.071-8.69-6.036
	c-0.7-0.421-1.82-0.28-2.66-0.28c-1.68,0-3.22,0-4.9,0c-2.52,0-5.46-0.702-7.56,0.28c-1.96,0.842-3.08,3.509-4.62,5.334
	c-0.7,0.842-1.54,1.825-2.52,2.386c-0.56,0.281-1.96,0.141-2.24-0.281c-1.68-2.386-2.66-5.053-4.62-7.158
	c-1.96-2.105-3.36-5.334-7-4.772c-3.5-3.088-6.72-0.983-8.54,1.544c-3.36,4.632-7.84,4.07-12.46,4.07c-1.68,0-2.66-0.281-2.52-2.246
	c0-0.421-0.98-0.982-1.4-1.544c-4.06-5.474-3.92-5.474-9.25-1.684c-1.82,1.263-2.52,3.93-5.32,4.071c-0.28,0-0.42,1.544-0.7,2.386
	c-0.98,2.807-2.66,4.07-5.04,2.667c-1.54-0.983-2.94-0.983-4.48-0.983c-0.56,0-1.4,0.421-1.54,0.842
	c-0.14,0.562,0.14,1.404,0.56,1.825c4.62,5.755,4.76,6.036-1.82,9.825c-3.36,1.965-5.6,4.632-7,7.861
	c-0.98,2.245-1.82,3.088-4.2,3.649c-2.38,0.421-4.48,1.544-4.62,5.193c0,3.65-5.04,7.159-8.68,7.299
	c-2.52,0.141-5.04,0.141-7.56,0.281c-0.14,2.807-0.28,5.474-0.42,8.281c0,1.685,0.28,3.369-0.14,4.913
	c-1.12,4.071,3.36,6.176,3.22,9.966c0,0.421,1.82,1.263,2.8,1.403c0.84,0.141,1.96-0.421,2.94-0.701c0.14,0,0.42-0.141,0.56-0.281
	c3.08-1.404,3.08-1.404,3.22,1.544c0,5.334,0,10.808,0,16.142c0,1.544,0.56,2.105,1.96,1.965c3.22-0.281,5.88,2.105,9.24,1.544
	c1.12-0.141,2.66,0.561,3.5,1.403c0.7,0.702,0.28,2.106,0.7,3.088c1.4,3.229,1.26,6.597,1.4,9.966c0.14,1.684,1.12,3.228,1.82,4.772
	c1.54,3.088,3.5,6.036,4.76,9.264c0.56,1.404,1.12,3.509-2.1,3.79c-1.68,0.14-3.36,2.105-4.62,3.509
	c-1.4,1.684-1.82,4.071-3.22,5.615c-4.62,5.053-10.22,8.842-17.36,8.702c-2.8,0-3.36,1.404-4.2,3.228
	c-0.7,1.404-1.4,2.667-1.82,4.071c-1.4,4.07-3.78,6.176-8.54,5.614c-2.52-0.28-5.18,0.983-7.84,1.264c-2.8,0.28-5.6-0.141-8.41,0.14
	c-0.98,0.14-2.1,0.842-2.8,1.544c-2.38,2.246-4.48,4.772-7,6.878c-7.28,6.035-14,12.768-21.98,17.968c-1.4,0.98-2.52,1.68-4.34,1.54
	C1203.91,1007.98,1200.83,1007.98,1197.75,1007.98z"/>

            </NavLink>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1319.56,474.346c0-8.625,7.21-15.596,15.93-15.596
	c8.74,0,15.95,6.971,15.95,15.596c0,4.346-1.58,8.381-4.18,11.801c-2.87,3.772-6.41,7.059-10.39,9.639
	c-0.92,0.596-1.74,0.641-2.74,0c-4-2.58-7.54-5.867-10.39-9.639C1321.14,482.727,1319.56,478.692,1319.56,474.346z M1330.24,474.831
	c0,2.89,2.36,5.162,5.25,5.162s5.27-2.272,5.27-5.162c0-2.866-2.38-5.249-5.27-5.249S1330.24,471.965,1330.24,474.831z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1225.56,798.346c0-8.625,7.21-15.596,15.93-15.596
	c8.74,0,15.95,6.971,15.95,15.596c0,4.346-1.58,8.381-4.18,11.801c-2.87,3.772-6.41,7.059-10.39,9.639
	c-0.92,0.596-1.74,0.641-2.74,0c-4-2.58-7.54-5.867-10.39-9.639C1227.14,806.727,1225.56,802.692,1225.56,798.346z M1236.24,798.831
	c0,2.89,2.36,5.162,5.25,5.162s5.27-2.272,5.27-5.162c0-2.866-2.38-5.249-5.27-5.249S1236.24,795.965,1236.24,798.831z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1136.4,807.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.702c-2.36,3.102-5.27,5.805-8.55,7.926c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.926C1137.69,814.798,1136.4,811.48,1136.4,807.907z M1145.17,808.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1145.17,805.949,1145.17,808.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1140.67,734.757c0-6.133,5.12-11.09,11.32-11.09
	c6.22,0,11.34,4.957,11.34,11.09c0,3.091-1.12,5.96-2.97,8.392c-2.04,2.682-4.56,5.02-7.39,6.854c-0.65,0.424-1.23,0.456-1.94,0
	c-2.85-1.834-5.37-4.172-7.39-6.854C1141.79,740.717,1140.67,737.848,1140.67,734.757z M1148.26,735.102
	c0,2.055,1.68,3.671,3.73,3.671c2.06,0,3.75-1.616,3.75-3.671c0-2.038-1.69-3.733-3.75-3.733
	C1149.94,731.369,1148.26,733.064,1148.26,735.102z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M985.396,486.907c0-7.092,5.926-12.824,13.094-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.547,7.925
	c-0.749,0.49-1.425,0.527-2.248,0c-3.292-2.121-6.201-4.824-8.54-7.925C986.694,493.798,985.396,490.48,985.396,486.907z
	 M994.174,487.306c0,2.375,1.939,4.244,4.316,4.244c2.38,0,4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317
	C996.113,482.989,994.174,484.949,994.174,487.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1109.4,382.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1110.69,389.798,1109.4,386.48,1109.4,382.907z M1118.17,383.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1118.17,380.949,1118.17,383.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1128.4,276.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1129.69,283.798,1128.4,280.48,1128.4,276.907z M1137.17,277.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1137.17,274.949,1137.17,277.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1296.94,259.607c0-5.174,4.32-9.357,9.55-9.357
	c5.25,0,9.57,4.183,9.57,9.357c0,2.608-0.95,5.029-2.51,7.081c-1.72,2.263-3.84,4.236-6.23,5.783c-0.55,0.358-1.04,0.385-1.64,0
	c-2.4-1.547-4.53-3.52-6.23-5.783C1297.88,264.636,1296.94,262.215,1296.94,259.607z M1303.34,259.899
	c0,1.733,1.42,3.097,3.15,3.097c1.74,0,3.17-1.364,3.17-3.097c0-1.72-1.43-3.15-3.17-3.15
	C1304.76,256.749,1303.34,258.179,1303.34,259.899z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1261.4,353.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1262.69,360.798,1261.4,357.48,1261.4,353.907z M1270.17,354.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1270.17,351.949,1270.17,354.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1180.23,412.467c0-5.558,4.64-10.05,10.26-10.05
	c5.64,0,10.28,4.492,10.28,10.05c0,2.801-1.02,5.401-2.69,7.605c-1.85,2.431-4.13,4.55-6.7,6.212c-0.59,0.384-1.12,0.413-1.76,0
	c-2.58-1.662-4.86-3.781-6.7-6.212C1181.25,417.868,1180.23,415.268,1180.23,412.467z M1187.11,412.78
	c0,1.862,1.52,3.327,3.38,3.327c1.87,0,3.4-1.465,3.4-3.327c0-1.847-1.53-3.383-3.4-3.383
	C1188.63,409.397,1187.11,410.933,1187.11,412.78z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1218.4,555.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1219.69,562.798,1218.4,559.48,1218.4,555.907z M1227.17,556.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1227.17,553.949,1227.17,556.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1173.4,638.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1174.69,645.798,1173.4,642.48,1173.4,638.907z M1182.17,639.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1182.17,636.949,1182.17,639.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1311.4,592.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1312.69,599.798,1311.4,596.48,1311.4,592.907z M1320.17,593.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1320.17,590.949,1320.17,593.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1425.4,583.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1426.69,590.798,1425.4,587.48,1425.4,583.907z M1434.17,584.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1434.17,581.949,1434.17,584.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1480.4,549.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1481.69,556.798,1480.4,553.48,1480.4,549.907z M1489.17,550.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1489.17,547.949,1489.17,550.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1335.4,531.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1336.69,538.798,1335.4,535.48,1335.4,531.907z M1344.17,532.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1344.17,529.949,1344.17,532.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1388.4,328.907c0-7.092,5.92-12.824,13.09-12.824
	c7.19,0,13.11,5.732,13.11,12.824c0,3.573-1.3,6.891-3.43,9.703c-2.36,3.101-5.27,5.804-8.55,7.925c-0.75,0.49-1.42,0.527-2.24,0
	c-3.3-2.121-6.21-4.824-8.55-7.925C1389.69,335.798,1388.4,332.48,1388.4,328.907z M1397.17,329.306c0,2.375,1.94,4.244,4.32,4.244
	s4.34-1.869,4.34-4.244c0-2.357-1.96-4.317-4.34-4.317S1397.17,326.949,1397.17,329.306z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#19322D" d="M1241.79,458.178c0-4.984,4.17-9.011,9.2-9.011
	c5.05,0,9.22,4.027,9.22,9.011c0,2.511-0.91,4.842-2.42,6.818c-1.66,2.179-3.7,4.079-6,5.569c-0.53,0.345-1,0.371-1.58,0
	c-2.31-1.49-4.36-3.39-6-5.569C1242.7,463.02,1241.79,460.689,1241.79,458.178z M1247.96,458.458c0,1.67,1.36,2.982,3.03,2.982
	s3.05-1.312,3.05-2.982c0-1.656-1.38-3.033-3.05-3.033S1247.96,456.802,1247.96,458.458z"/>
        </StyledLandingHeaderSvg>
    );
}

export default LandingHeaderSvg;