import React from 'react';

function ZoomSvg(props:any) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2506 14.2175L12.4681 11.4575C13.5481 10.1108 14.0712 8.40147 13.9297 6.68097C13.7882 4.96048 12.9928 3.35959 11.7072 2.20748C10.4216 1.05538 8.74342 0.439632 7.01776 0.486853C5.2921 0.534073 3.65011 1.24067 2.42942 2.46136C1.20874 3.68204 0.502141 5.32403 0.454921 7.04969C0.4077 8.77536 1.02345 10.4535 2.17555 11.7391C3.32765 13.0248 4.92854 13.8201 6.64904 13.9616C8.36954 14.1031 10.0789 13.5801 11.4256 12.5L14.1856 15.26C14.2553 15.3303 14.3382 15.3861 14.4296 15.4242C14.521 15.4622 14.6191 15.4818 14.7181 15.4818C14.8171 15.4818 14.9151 15.4622 15.0065 15.4242C15.0979 15.3861 15.1808 15.3303 15.2506 15.26C15.3857 15.1202 15.4613 14.9333 15.4613 14.7388C15.4613 14.5442 15.3857 14.3574 15.2506 14.2175ZM7.21807 12.5C6.17972 12.5 5.16468 12.1921 4.30132 11.6152C3.43797 11.0383 2.76506 10.2184 2.3677 9.25909C1.97034 8.29978 1.86637 7.24418 2.06895 6.22578C2.27152 5.20738 2.77153 4.27191 3.50576 3.53769C4.23998 2.80346 5.17544 2.30345 6.19384 2.10088C7.21224 1.8983 8.26784 2.00227 9.22716 2.39963C10.1865 2.79699 11.0064 3.4699 11.5833 4.33326C12.1602 5.19661 12.4681 6.21165 12.4681 7.25C12.4681 8.64239 11.9149 9.97774 10.9304 10.9623C9.94581 11.9469 8.61045 12.5 7.21807 12.5Z"
                fill="#6EC1B1"/>
        </svg>
    );
}

export default ZoomSvg;