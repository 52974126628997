import React from 'react';
import styled from "styled-components";
import {Button, Form, Input, message} from "antd";
import axios from "axios";
import {api} from "../config";
import History from "../utils/history";

const StyledLoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: #ecf2ff;
  min-height: 100vh;
`;

function LoginPage({setUser}: any) {
    const handleLogin = (fields: any) => {
        axios.post(`${api}/auth/login`, {
            login: fields.login,
            password: fields.password
        }).then(({data}) => {
            setUser(data)
            History.push("/dashboard")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <StyledLoginPage>
            <h1>Авторизация</h1>
            <Form hideRequiredMark layout={"vertical"} onFinish={handleLogin}>
                <Form.Item rules={[{
                    required: true
                }]} label={"Логин"} name={"login"}>
                    <Input/>
                </Form.Item>
                <Form.Item rules={[{
                    required: true
                }]} label={"Пароль"} name={"password"}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item>
                    <Button style={{width: "100%"}} htmlType={"submit"}>Войти</Button>
                </Form.Item>
            </Form>
        </StyledLoginPage>
    );
}

export default LoginPage;