import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Divider, Form, Input, message, Modal, Select, Space, Tag, Typography, Upload} from "antd";
import UploadSvg from "../svg-components/UploadSvg";
import axios from "axios";
import {api} from "../config";
import {brightnessByColor} from "../utils/getBrightnessByColor";
import PlusSvg from "../svg-components/PlusSvg";
import CreateMarkForm from "./CreateMarkForm";

const StyledButton = styled(Button)`
  svg {
    margin-right: 5px;

    path {
      fill: gray;
    }
  }
`;

const StyledEditAreaForm = styled.div`
  display: flex;
  width: 100%;

  .ant-form {
    width: 100%;

    .ant-row {
      margin-bottom: 15px;
    }
  }
`;

const tagRender = (props: any) => {
    console.log(props);
    const {value, color, label, closable, onClose} = props;
    const onPreventMouseDown = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={color}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            closeIcon={<span style={{color: "#000", fontSize: 12, fontWeight: '500'}}> x</span>}
            onClose={onClose}
            style={{marginRight: 3, color: brightnessByColor(color || "#fff") < 127 ? "#fff" : "#000"}}
        >
            {label}
        </Tag>
    );
}

function EditAreaForm({getMarks, marks, form, onFinish, data}: any) {
    const [createMarkVisible, setCreateMarkVisible] = useState<boolean>(false);
    const [createMarkForm] = Form.useForm();

    const createMark = (fields:any) => {
        axios.post(`${api}/marks`, fields).then(()=>{
            getMarks();
            setCreateMarkVisible(false);
            createMarkForm.resetFields();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    useEffect(() => {
        getMarks();
    }, [])

    useEffect(() => {
        form.setFieldsValue({...data, coords: `${data.latitude}, ${data.longitude}`})
        console.log(data);
    }, [data])

    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <StyledEditAreaForm>
            <Modal onOk={()=>createMarkForm.submit()} visible={createMarkVisible} onCancel={()=>setCreateMarkVisible(false)} title={"Создание метки"} okText={"Создать"} cancelText={"Отмена"}>
                <CreateMarkForm onFinish={createMark} form={createMarkForm}/>
            </Modal>
            <Form hideRequiredMark onFinish={onFinish} form={form || undefined} layout={"vertical"}>
                <Form.Item label={"Кадастровый номер"}>
                    <Form.Item name={"number"} rules={[{
                        required: true
                    }]}>
                        <Input placeholder={"Номер"}/>
                    </Form.Item>
                    <Form.Item rules={[{
                        required: true
                    }]} name={"numberLink"}>
                        <Input placeholder={"Ссылка"}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item rules={[{
                    required: true
                }]} name={"square"} label={"Уточненная площадь"}>
                    <Input suffix={"кв.м"}/>
                </Form.Item>
                <Form.Item label={"Метка"} name={"marks"}>
                    <Select tagRender={(fields: any) => tagRender({
                        ...fields,
                        color: marks.find((mark: any) => mark.id === fields.value)?.color
                    })} mode="multiple" style={{width: '100%'}} dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{margin: '8px 0'}}/>
                            <Space align="center" style={{padding: '0 8px 4px'}}>
                                <Typography.Link onClick={() => setCreateMarkVisible(true)} style={{whiteSpace: 'nowrap', display: 'flex'}}>
                                    <PlusSvg/> Добавить метку
                                </Typography.Link>
                            </Space>
                        </>
                    )} options={marks.map((mark: any) => {
                        return {value: mark.id, label: mark.name}
                    })}/>
                </Form.Item>
                <Form.Item label={"Координаты"}>
                    <Form.Item rules={[{
                        required: true
                    }]} name={"coords"}>
                        <Input placeholder={"Координаты"}/>
                    </Form.Item>
                    <Form.Item rules={[{
                        required: true
                    }]} name={"coordsLink"}>
                        <Input placeholder={"Ссылка"}/>
                    </Form.Item>
                </Form.Item>
                {/*<Form.Item rules={[{*/}
                {/*    required: true*/}
                {/*}]} getValueFromEvent={normFile} name={"photo"} label={"Фото"} valuePropName="fileList">*/}
                {/*    <Upload beforeUpload={() => false} customRequest={() => {*/}
                {/*    }} showUploadList={{*/}
                {/*        showDownloadIcon: false,*/}
                {/*        showRemoveIcon: false,*/}
                {/*        showPreviewIcon: false*/}
                {/*    }} maxCount={1} name={"photo"}>*/}
                {/*        <StyledButton icon={<UploadSvg/>}>Загрузить</StyledButton>*/}
                {/*    </Upload>*/}
                {/*</Form.Item>*/}
                <Form.Item name={"comment"} label={"Комментарий"}>
                    <Input.TextArea/>
                </Form.Item>

            </Form>
        </StyledEditAreaForm>
    );
}

export default EditAreaForm;