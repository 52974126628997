import React from 'react';

function UploadSvg(props:any) {
    return (
        <svg {...props} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.67857 2.81731H5.99821V8.85838C5.99821 8.93696 6.0625 9.00124 6.14107 9.00124H7.2125C7.29107 9.00124 7.35536 8.93696 7.35536 8.85838V2.81731H8.67857C8.79821 2.81731 8.86429 2.67981 8.79107 2.58696L6.79107 0.0548123C6.77771 0.0377377 6.76063 0.0239288 6.74114 0.0144321C6.72165 0.0049354 6.70025 0 6.67857 0C6.65689 0 6.63549 0.0049354 6.616 0.0144321C6.59651 0.0239288 6.57943 0.0377377 6.56607 0.0548123L4.56607 2.58517C4.49286 2.67981 4.55893 2.81731 4.67857 2.81731ZM13.2143 8.32267H12.1429C12.0643 8.32267 12 8.38696 12 8.46553V11.2155H1.35714V8.46553C1.35714 8.38696 1.29286 8.32267 1.21429 8.32267H0.142857C0.0642857 8.32267 0 8.38696 0 8.46553V12.0012C0 12.3173 0.255357 12.5727 0.571429 12.5727H12.7857C13.1018 12.5727 13.3571 12.3173 13.3571 12.0012V8.46553C13.3571 8.38696 13.2929 8.32267 13.2143 8.32267Z"
                fill="black"/>
        </svg>
    );
}

export default UploadSvg;