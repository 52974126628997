import React, {useEffect, useState} from 'react';
import axios from "axios";
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import MainPage from "./pages/MainPage";
import {api} from "./config";
import "./styles/main.css";
import LoginPage from "./pages/LoginPage";
import History from "./utils/history";
import LandingPage from "./pages/LandingPage";

function App() {

    History.navigate = useNavigate();

    const [user, setUser] = useState({
        type: "guest"
    });

    useEffect(() => {
        axios.post(`${api}/auth/refresh`).then(({data}) => {
            setUser(data)
        }).catch(() => {
            // History.push("/login")
        })
    }, [])

    return (
        <Routes>
            <React.Fragment>
                <Route path={"/"} element={<LandingPage/>}/>
            </React.Fragment>
            {!!user && <React.Fragment><Route path={"/dashboard"} element={<MainPage setUser={setUser} user={user}/>}/></React.Fragment>}
            <Route path={"/login"} element={<LoginPage setUser={setUser}/>}/>
        </Routes>
    );
}

export default App;
