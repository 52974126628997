import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import Header from "../components/Header";
import PlusSvg from "../svg-components/PlusSvg";
import {api} from "../config";
import {Button, Form, Input, message, Modal, Select, Tag} from "antd";
import CreateAreaForm from "../components/CreateAreaForm";
import EditPenSvg from "../svg-components/EditPenSvg";
import RemoveSvg from "../svg-components/RemoveSvg";
import ExcludeSvg from "../svg-components/ExcludeSvg";
import IncludeSvg from '../svg-components/IncludeSvg';
import EditAreaForm from "../components/EditAreaForm";
import UserCreateForm from "../components/UserCreateForm";
import {brightnessByColor} from "../utils/getBrightnessByColor";
import ZoomSvg from "../svg-components/ZoomSvg";
import CompassSvg from "../svg-components/CompassSvg";
import LocationSvg from "../svg-components/LocationSvg";
import AreaValueSvg from "../svg-components/AreaValueSvg";
import {NavLink} from "react-router-dom";

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.8% 3%;
  overflow: auto;
  background: #F7FFFE;
  border: 1px solid #B2DED6;
  border-radius: 36px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 1.8% 2%;

  overflow: auto;

  h2 {
    font-size: min(2.4vw, 24px);
    color: #1a2d43;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: normal;

  }

  p {
    font-size: min(2vw, 20px);
    color: #5a5a5a;
    font-weight: 500;
    line-height: normal;
  }

  a {
    display: flex;
    align-items: center;
    color: #2774f9;
    font-size: min(2vw, 20px);
    margin-bottom: 20px;
    line-height: normal;
    background: #EFF5FF;
    border-radius: 12px;
    padding: 3% 5%;
    font-weight: 600;

    svg {
      margin-right: 16px;
    }
  }
`;

const Profile = styled.div`

`;

const Top = styled.div`
  display: flex;
`;

/*TODO: придумать что сделать с фоткой,она при сужении экрана сужается по уебански*/
const Img = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 560px;
  max-width: 560px;
  margin-right: 2.1%;
  background: #000;
  margin-bottom: 25px;
  border-radius: 22px;
  overflow: hidden;

  img {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  a {
    overflow-wrap: anywhere;
  }
  svg {
    margin-right: min(1.6vw, 16px);
    flex: 0 0 auto;
    width: min(2.6vw, 26px);
    height: min(2.6vw, 26px);
  }
  p {
    &.areaValue {
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #E4E4E4;
      border-radius: 12px;
      padding: 3% 5%;
      font-weight: 600;
    }
  }

`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentTop = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;

  svg {
    margin-right: 10px;
  }
`;
const Placeholder = styled.div`
  display: flex;
  align-items: center;
  left: 4px;
  position: relative;
  color: #6ec1b1;
  font-size: min(1.6vw, 16px);

  svg {
    margin-right: 5px;
  }
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0 30px 0 0;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: min(1.8vw, 18px) min(2.4vw, 24px);
      background: #F7FFFE;
      border: 1px solid #CDF3EC;
      border-radius: 55px;
      margin-right: 23px;
    }
  }
`;

const Right = styled.div`
  padding: 1.8% 2%;
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  overflow: hidden;

`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  overflow-y: auto;
  padding-right: 17px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 21px;
    background: #D9D9D9;
  }

`;

const ListAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F3F3F3;
  border-bottom: 1px solid #D0D0D0;
  padding: 5px 0;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  svg {
    height: 40px;
    width: 40px;
  }
`;

const ListItemWrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 17px;
  background: #F7FFFE;
  border: 1px solid #CDF3EC;
  border-radius: 12px;
  cursor: pointer;

  &.excluded {
    background: #fff2f2;
  }

  &.active {
    background: #DEFFFB;
    border: 1px solid #2EBFA3;
  }

  &:hover {
    background: #DEFFFB;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: min(2.4vw, 24px);
  color: #000000;
`;

const Marks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .ant-tag {
    margin: 10px 0 0;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;

  h1 {
    font-size: min(3.2vw, 32px);
    font-weight: 700;
    color: #253753;
  }
`;

const StyledMainPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: 'Source Sans Pro', sans-serif;

  .ant-tag {
    text-transform: capitalize;
    padding: min(.6vw, 6px) min(2.4vw, 24px);
    border-radius: 24px;
    font-size: min(1.4vw, 14px);
  }
`;

function MainPage({setUser, user}: any) {

    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
    const [editFormVisible, setEditFormVisible] = useState<boolean>(false);

    const [selectedAreaId, setSelectedAreaId] = useState<any>(null);

    const [editableAreaId, setEditableAreaId] = useState<any>(null);

    const [areas, setAreas] = useState<any>([]);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const [createForm] = Form.useForm();

    const selectedArea = areas.find((el: any) => el.id === selectedAreaId);
    const editableArea = areas.find((el: any) => el.id === editableAreaId);

    const [editing, setEditing] = useState<boolean>(false);
    const [newComment, setNewComment] = useState<any>("");

    const [marks, setMarks] = useState<any>([]);
    const [selectedMarks, setSelectedMarks] = useState<any>([]);

    const getMarks = () => {
        axios(`${api}/marks`).then(({data}) => {
            setMarks(data);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const onFinish = (fields: any) => {

        const formData = new FormData();

        formData.append("photo", fields.photo?.[0]?.originFileObj);
        formData.append("number", fields.number);
        formData.append("numberLink", fields.numberLink);
        formData.append("square", fields.square);
        formData.append("marks", JSON.stringify(fields.marks));
        formData.append("coords", fields.coords);
        formData.append("coordsLink", fields.coordsLink);
        formData.append("comment", fields.comment || "");

        axios.post(`${api}/area`, formData).then(() => {
            getAreas()
            form.resetFields();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const tagRender = (props: any) => {
        console.log(props);
        const {color, label, closable, onClose} = props;
        const onPreventMouseDown = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={"#2EBFA3"}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                closeIcon={<span style={{color: "#000", fontSize: 12, fontWeight: '500'}}> x</span>}
                onClose={onClose}
                style={{marginRight: 3, color: "#fff"}}
            >
                {label}
            </Tag>
        );
    }


    const onEdit = (fields: any) => {
        axios.put(`${api}/area/edit/${editableAreaId}`, fields).then(() => {
            getAreas();
            setEditFormVisible(false);
            form2.resetFields();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    useEffect(() => {
        getAreas();
        getMarks();
    }, [])
    useEffect(() => {
        if (selectedAreaId === null && areas.length > 0) {
            setSelectedAreaId(areas[0].id)
        }
    }, [areas])

    const editComment = () => {
        axios.put(`${api}/area/${selectedAreaId}`, {
            comment: newComment
        }).then(() => {
            setEditing(false);
            getAreas();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const getAreas = () => {
        axios(`${api}/areas`).then(({data}) => setAreas(data)).catch(({response}) => {
            message.error(response?.data || "Error")
        });
    }

    const toggleExclude = (id: any) => {
        axios.put(`${api}/area/${id}/toggle`).then(() => {
            getAreas();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const onUserCreate = (fields: any) => {
        axios.post(`${api}/user`, fields).then(() => {
            setCreateFormVisible(false);
            createForm.resetFields();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <StyledMainPage>
            <Header onUserCreate={() => setCreateFormVisible(true)} setUser={setUser} user={user}/>
            <Content>
                <Modal okText={"Создать"} onOk={createForm.submit} cancelText={"Отмена"}
                       onCancel={() => setCreateFormVisible(false)} visible={createFormVisible}
                       title={"Создание пользователя"}>
                    <UserCreateForm onFinish={onUserCreate} form={createForm}/>
                </Modal>
                <Modal okText={"Сохранить"} cancelText={"Отмена"} title={"Редактирование участка"}
                       onOk={form2.submit}
                       visible={editFormVisible} onCancel={() => setEditFormVisible(false)}>
                    <EditAreaForm getMarks={getMarks} marks={marks} onFinish={onEdit} data={editableArea} form={form2}/>
                </Modal>
                <Modal okText={"Сохранить"} cancelText={"Отмена"} title={"Создание участка"} visible={formVisible}
                       onOk={form.submit}
                       onCancel={() => setFormVisible(false)}>
                    <CreateAreaForm getMarks={getMarks} marks={marks} onFinish={onFinish} form={form}/>
                </Modal>
                <Left>
                    <h1>Профайл{selectedArea?.excluded && <span style={{color: "#ff3838"}}>(Исключен)</span>}</h1>
                    <LeftContent>
                        {selectedArea && <Profile>
                            <Top>
                                <Img><img
                                    src={`${api}/${selectedArea.photoUrl}`}
                                    alt=""/></Img>
                                <Info>
                                    <h2>Кад.номер</h2>
                                    <a target={"_blank"} rel={"noopener noreferrer"}
                                       href={selectedArea.numberLink}><CompassSvg/>{selectedArea.number}</a>
                                    <h2>Координаты</h2>
                                    <a target={"_blank"} rel={"noopener noreferrer"}
                                       href={selectedArea.coordsLink}><LocationSvg/>{selectedArea.latitude}, {selectedArea.longitude}
                                    </a>
                                    <h2>Уточненная площадь</h2>
                                    <p className={"areaValue"}><AreaValueSvg/>{selectedArea.square} кв.м</p>
                                    <Comment>
                                        <CommentTop><h2>Комментарий</h2>{user.type !== "guest" &&
                                            <EditPenSvg onClick={() => {
                                                setEditing(true)
                                                setNewComment(selectedArea.comment)
                                            }}/>}</CommentTop>
                                        {editing ? <React.Fragment>
                                                <Input.TextArea value={newComment}
                                                                onChange={({currentTarget}: any) => setNewComment(currentTarget.value)}/>
                                                <Button onClick={editComment} style={{marginTop: 20}}>Сохранить</Button>
                                            </React.Fragment> :
                                            <p>{selectedArea.comment}</p>}
                                    </Comment>
                                </Info>
                            </Top>
                        </Profile>}
                    </LeftContent>
                </Left>
                <Right>
                    <h1>Участки</h1>
                    <Filters>
                        <Select showSearch={false} value={selectedMarks}
                                onChange={setSelectedMarks}
                                placeholder={<Placeholder><ZoomSvg/>Поиск</Placeholder>}
                                mode={"tags"} tagRender={(fields: any) => tagRender({
                            ...fields,
                            color: marks.find((mark: any) => mark.id === fields.value)?.color
                        })} options={marks.map((mark: any) => {
                            return {value: mark.id, label: mark.name.toLowerCase()}
                        })}/>
                    </Filters>
                    <List>
                        {user?.type === "admin" && <ListAdd onClick={() => setFormVisible(true)}><PlusSvg/></ListAdd>}
                        {areas?.filter((area: any) => selectedMarks.length > 0 ? area.marks?.some((mark: any) => selectedMarks.includes(mark)) : true)?.map?.((area: any, index: number) =>
                            <ListItemWrap
                                className={selectedAreaId === area.id ? "active" : area?.excluded === true ? "excluded" : "false"}
                                key={index} onClick={() => {
                                setSelectedAreaId(area.id)
                            }}>
                                <Marks>
                                    {area?.marks?.map?.((markId: any, index: any) => {
                                        const mark = marks.find((mark: any) => markId === mark.id);
                                        if (mark === undefined)
                                            return null
                                        return <Tag key={index} color={"#2EBFA3"}
                                                    style={{color: "#fff"}}>{mark.name.toLowerCase()}</Tag>
                                    })}
                                </Marks>
                                <ListItem

                                >Земельный
                                    участок с КН {area.number}<Actions>
                                        {user?.type === "admin" && <EditPenSvg onClick={(e: any) => {
                                            e.stopPropagation();
                                            setEditableAreaId(area.id);
                                            setEditFormVisible(true);
                                        }}/>
                                        } {user?.type !== "guest" && (area?.excluded ?
                                        <IncludeSvg onClick={(e: any) => {
                                            e.stopPropagation();
                                            toggleExclude(area.id)
                                        }}/> : <ExcludeSvg onClick={(e: any) => {
                                            e.stopPropagation();
                                            toggleExclude(area.id)
                                        }}/>)}
                                        {user?.type === "admin" && <RemoveSvg onClick={(e: any) => {
                                            new (Modal as any).confirm({
                                                content: "Вы уверены что хотите удалить",
                                                onOk: () => {
                                                    axios.delete(`${api}/area/${area.id}`).then(() => {
                                                        message.warning("Удаление прошло успешно!")
                                                        getAreas();
                                                    }).catch(({response}) => {
                                                        message.error(response?.data || "Error")
                                                    })
                                                }
                                            })
                                            e.stopPropagation()
                                            console.log(area.id)
                                        }}/>}
                                    </Actions>
                                </ListItem>
                            </ListItemWrap>)}
                    </List>
                    {/*<input type="file" onChange={(e) => (e.target.files !== null && setFile(e.target.files[0]))}/>*/}
                    {/*<button onClick={send}>Отправить</button>*/}

                </Right>
            </Content>
        </StyledMainPage>
    );
}

export default MainPage;