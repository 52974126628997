import React from 'react';

function CompassSvg(props:any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.666504 14.0001C0.666504 6.64013 6.6265 0.666801 13.9998 0.666801C21.3598 0.666801 27.3332 6.64013 27.3332 14.0001C27.3332 21.3735 21.3598 27.3335 13.9998 27.3335C6.6265 27.3335 0.666504 21.3735 0.666504 14.0001ZM16.9732 16.4401L19.1332 9.61347C19.2798 9.1468 18.8532 8.7068 18.3865 8.85347L11.5598 10.9868C11.2798 11.0801 11.0532 11.2935 10.9732 11.5735L8.83984 18.4135C8.69317 18.8668 9.13317 19.3068 9.5865 19.1601L16.3865 17.0268C16.6665 16.9468 16.8932 16.7201 16.9732 16.4401Z"
                  fill="#2C7AF6"/>
        </svg>
    );
}

export default CompassSvg;