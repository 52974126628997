import React, {useState} from 'react';
import {Form, Input, Tag} from "antd";
import {HuePicker} from "react-color";
import {brightnessByColor} from "../utils/getBrightnessByColor";

function CreateMarkForm({onFinish, form}:any) {
    const [color, setColor] = useState<any>("#fff");
    const [name, setName] = useState<any>("");

    return (
        <Form form={form} layout={"vertical"} onFinish={(fields)=> {
            onFinish({...fields, color: fields?.color?.hex || "#fff"})
        }}>
            <Form.Item label={"Имя метки"} name={"name"}>
                <Input value={name} onChange={({target: {value}}: any) => setName(value)}/>
            </Form.Item>
            <Form.Item label={"Цвет метки"} name={"color"}>
                <HuePicker color={color}
                           onChangeComplete={(color) => setColor(color)}
                           onChange={(color) => setColor(color)}/>
            </Form.Item>
            <Form.Item label={"Пример"}>
                <Tag color={color.hex}
                     style={{color: brightnessByColor(color?.hex || "#fff") < 127 ? "#fff" : "#000"}}>{name || "Метка"}</Tag>
            </Form.Item>
        </Form>
    );
}

export default CreateMarkForm;